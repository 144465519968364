@if (show_search_input) {
  <div class="box-search frosted-glass">
    <div class="input-group fg-background">
      <div class="input-group-text bg-transparent px-2_5">
        <i class="bi bi-search fs-4"></i>
      </div>
      <input
        #inputSearchElement
        [(ngModel)]="search_input"
        class="search-input form-control bg-transparent fs-5 px-1_5 py-2 border-0"
        type="text"
        [placeholder]="univers_placeholder"
        style="min-width: 100px"
        (keyup.enter)="search()"
        (keyup)="debounceSearch(getValue($event))"
      />
      <div class="input-group-text bg-transparent px-2_5">
        <i class="bi bi-x-lg fs-4 cursor-pointer" (click)="resetSearch()"></i>
      </div>
      <div [ngClass]="{ separator: show_univers }"></div>
      <div *ngIf="show_univers">
        <div
          *ngIf="univers_list.length"
          class="input-group-text d-flex bg-transparent flex-wrap gap-2 py-1 px-2"
          style="margin-left: 1px"
        >
          <ng-container *ngFor="let univers of univers_list; let i = index">
            <input
              type="radio"
              class="btn-check"
              id="{{ 'univers-' + i }}"
              [(ngModel)]="selected_univers"
              [value]="univers"
              (change)="onChangeUnivers()"
            />
            <label
              class="btn px-2 py-1"
              for="{{ 'univers-' + i }}"
              [ngClass]="isSelectedUniverse(univers.libelle) ? 'btn-primary' : 'btn-dark'"
              >{{ univers.libelle }}</label
            >
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <form [formGroup]="filters_form" *ngIf="selected_univers && selected_univers.filters.length" class="mt-3">
    <div class="d-flex justify-content-between">
      <div class="col-10">
        <app-filters formControlName="filters" [filtersToDisplay]="selected_univers.filters"></app-filters>
      </div>
      @if (selected_univers.libelle === 'ERP') {
        <div class="col-2 d-flex justify-content-end align-items-start">
          <button class="btn btn-light" (click)="exportSearch()"><i class="bi bi-filetype-csv fs-5"></i> Export</button>
        </div>
      }
    </div>
  </form>
}
