import { TitleCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Utilisateur } from '@metarisc/metarisc-js/lib/model/Utilisateur';

interface Affectation {
  id: string;
  utilisateur: Utilisateur;
  role: string;
}

@Component({
  selector: 'app-avatars-affectations',
  standalone: true,
  imports: [TitleCasePipe],
  templateUrl: './avatars-affectations.component.html',
  styleUrl: './avatars-affectations.component.scss',
})
export class AvatarsAffectationsComponent {
  @Input() affectations: Affectation[] = [];
}
