import { Component, effect, LOCALE_ID, OnInit } from '@angular/core';
import { NavigationStart, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { SidebarService } from 'src/app/services/sidebar/sidebar.service';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Utilisateur } from '@metarisc/metarisc-js/src/model/Utilisateur';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MetariscService } from 'src/app/services/metarisc/metarisc.service';
import { ToastNotificationService } from 'src/app/services/toast-notification/toast-notification.service';
import { SidebarCustomItem, SidebarCustomUniverse } from 'src/app/services/sidebar/sidebar-custom.model';
import { environment } from 'src/environments/environment';
import { NgClass, NgFor, NgIf, registerLocaleData, TitleCasePipe } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { OrganisationMembre } from '@metarisc/metarisc-js/lib/model/OrganisationMembre';
import { NotificationListenerService } from 'src/app/services/notification-listener/notification-listener.service';
import { OrganisationChoiceService } from 'src/app/services/organisation-choice/organisation-choice.service';
import { TruncateStringPipe } from 'src/app/shared/pipes/truncate-string.pipe';

import { LoadingComponent } from 'src/app/shared/components/loading/loading.component';
import { EventEnum } from '@metarisc/metarisc-js/lib/client';
import { CookieService } from 'ngx-cookie-service';
import { jwtDecode } from 'jwt-decode';
import { RechercheService } from 'src/app/features/recherche/services/recherche-service/recherche.service';
import { ToastNotificationComponent } from 'src/app/services/toast-notification/toast-notification.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilAiComponent } from 'src/app/features/dossier/components/fil-ai/fil-ai.component';

@Component({
  templateUrl: './connected-layout.component.html',
  styleUrls: ['./connected-layout.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'fr' }],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    RouterLink,
    RouterLinkActive,
    NgIf,
    NgFor,
    TitleCasePipe,
    TruncateStringPipe,
    RouterOutlet,
    LoadingComponent,
    ToastNotificationComponent,
  ],
})
export class ConnectedLayout implements OnInit {
  user?: Utilisateur;
  organisation?: OrganisationMembre;

  unread_counter = this.notificationListenerService.unread_notification_counter;

  universes = this.sb.sidebar;
  universeShow: { name: string; show: boolean }[] = [];
  currentRoute!: string;

  searchForm!: FormGroup;

  environmentOptions = environment.sidebar;
  isHovered = { ar: false, sdd: false, elc: false, notif: false, cal: false };

  constructor(
    private authService: AuthService,
    private sb: SidebarService,
    private router: Router,
    private formBuilder: FormBuilder,
    private metariscService: MetariscService,
    private toastNotificationService: ToastNotificationService,
    private notificationListenerService: NotificationListenerService,
    private cookie: CookieService,
    private organisationChoiceService: OrganisationChoiceService,
    private rechercheService: RechercheService,
    private ngbModal: NgbModal,
  ) {
    effect(() => {
      this.universes().forEach((universe) => {
        const isTempPin = universe.items.some((e) => e.checkable && !e.checked);
        if (isTempPin) {
          this.changeShow(universe, true);
        }
      });
    });
  }

  ngOnInit(): void {
    registerLocaleData(localeFr, 'fr');
    this.metariscService.getMetarisc().on(EventEnum.response, () => {
      const accessToken = this.metariscService.getMetarisc().getAccessToken();
      const refreshToken = this.metariscService.getMetarisc().getRefreshToken();

      if (accessToken && refreshToken) {
        const decodedAccessToken = jwtDecode(accessToken.split(' ')[1]);
        const decodedRefreshToken = jwtDecode(refreshToken);

        if (decodedAccessToken.exp && decodedRefreshToken.exp) {
          const accessTokenExpiration = new Date(decodedAccessToken.exp * 1000);
          const refreshTokenExpiration = new Date(decodedRefreshToken.exp * 1000);
          const atSession = this.cookie.get('access_token');

          if (accessToken !== atSession) {
            this.cookie.set('access_token', accessToken, { expires: accessTokenExpiration });
            this.cookie.set('refresh_token', refreshToken, { expires: refreshTokenExpiration });
            // Increment the refresh token count to allow multiple browser tab sync
            const currentCount = Number(localStorage.getItem('refreshTokenCount') || '0');
            localStorage.setItem('refreshTokenCount', (currentCount + 1).toString());
          }
        }
      }
    });

    // Listen for changes to update token in other browser tabs
    window.addEventListener('storage', (event) => {
      if (event.key === 'refreshTokenCount') {
        this.metariscService.getMetarisc().setAccessToken(this.cookie.get('access_token'));
        this.metariscService.getMetarisc().setRefreshToken(this.cookie.get('refresh_token'));
      }
    });

    // Initialisation du formulaire de recherche
    this.searchForm = this.formBuilder.group({
      rechercheInput: [''],
    });
    // Mise à jour des liens de la sidebar à chaque navigation
    this.sb.updateRoute(this.router.url);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.sb.updateRoute(event.url);
        //dans le changement de route
        if (event.url !== '/access') sessionStorage.setItem('lastUrl', event.url);
      }
    });
    this.universeShow = this.universes().map((e) => {
      return { name: e.name, show: this.isActualUniverse(e) };
    });
    // Appel API pour récupération des informations de l'utilisateur
    this.authService
      .getConnectedUser()
      .then((response) => {
        this.user = response;
        if (this.user.id) this.getUserOrganisation(this.user.id);
      })
      .catch((error) => {
        this.toastNotificationService.error(error.message);
      });
  }

  getUserOrganisation(user_id: string): void {
    this.metariscService
      .getMetarisc()
      .utilisateurs?.paginateUtilisateurOrganisations(user_id)
      .autoPagingIteratorToArray()
      .then((response) => {
        this.organisation = response.find(
          (org) => org.organisation?.id === this.organisationChoiceService.getOrganisationCookie(),
        );
      })
      .catch((error) => {
        this.toastNotificationService.error(
          "Erreur lors de la récupération de l'organisation active de l'utilisateur connecté (" + error.message + ')',
        );
      });
  }

  toggleLink(link: SidebarCustomItem): void {
    this.sb.toggleLink(link);
  }
  changeShow(universe: SidebarCustomUniverse, showed?: boolean): void {
    const u = this.universeShow.find((e) => e.name === universe.name);
    if (!u) return;

    if (showed !== undefined) {
      u.show = showed;
      return;
    }
    u.show = !u.show;
  }
  getShow(universeName: string): boolean {
    return this.universeShow.find((e) => e.name === universeName)?.show || false;
  }

  logout(): void {
    this.authService.logout();
  }

  isActualUniverse(universe: SidebarCustomUniverse): boolean {
    return universe.name === this.sb.actualUniverse;
  }

  universesDefaultSearch = new Map<string, string>([
    ['/erp', 'libelle'],
    ['/pei', 'numero'],
  ]);

  onSearch(): void {
    const rechercheInputValue = this.searchForm?.get('rechercheInput')?.value;
    this.searchForm?.patchValue({ rechercheInput: '' });
    // Navigate to the "/recherche" route and pass the search input value as a query parameter
    const universe = localStorage.getItem('currentUniverse') || '';
    const searchMethod = this.universesDefaultSearch.get(universe) || 'numero';
    if (rechercheInputValue.trim() !== '') {
      this.rechercheService.reloadSearch.next(rechercheInputValue);
      this.router.navigate(['/recherche' + universe], {
        queryParams: { [searchMethod]: rechercheInputValue },
      });
    } else {
      this.router.navigate(['/recherche' + universe]);
    }
  }

  getQueryParams(url: string): Record<string, string> {
    const params: Record<string, string> = {};
    const urlSplit = url.split('?');
    if (urlSplit.length > 1) {
      const paramsSplit = urlSplit[1].split('&');
      paramsSplit.forEach((param) => {
        const paramSplit = param.split('=');
        params[paramSplit[0]] = paramSplit[1];
      });
    }
    return params;
  }

  getUrlWithoutQueryParams(url: string): string {
    return url.split('?')[0];
  }
  openModalAI(): void {
    this.ngbModal.open(FilAiComponent, {
      size: 'lg',
      backdropClass: 'bg-transparent',
      scrollable: true,
      modalDialogClass: 'chat-ia-modal',
    });
  }
}
