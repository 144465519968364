import { Component, EventEmitter, forwardRef, inject, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UniversalFormInputComponent } from '../universal-form-input/universal-form-input.component';
import { ControlInfo, UniversalFormService } from 'src/app/services/universal-form/universal-form.service';
import { Layout, LayoutItem } from './types/universal-form-layout.type';
import { debounceTime } from 'rxjs';
import { UniversalFormDualSelectComponent } from '../universal-form-dual-select/universal-form-dual-select.component';
import { NameOpenApiObject } from 'src/assets/openapi/metariscObjectName';

@Component({
  selector: 'app-universal-form-layout',
  templateUrl: './universal-form-layout.component.html',
  styleUrls: ['./universal-form-layout.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UniversalFormInputComponent,
    UniversalFormDualSelectComponent,
    CommonModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UniversalFormLayoutComponent),
      multi: true,
    },
  ],
})
export class UniversalFormLayoutComponent implements OnInit {
  @Input({ required: true }) formGroup: FormGroup | undefined;
  @Input({ required: true }) resourceName!: NameOpenApiObject;
  @Input({ required: true }) layoutName: string = '';
  @Input() parentPath: string = '';
  @Input() readOnly: boolean = false;

  @Output() tabChange = new EventEmitter<string>();

  private universalFormService = inject(UniversalFormService);
  controlMap: Map<string, ControlInfo> = new Map();

  layout!: Layout;
  activeTab: string = '';
  currentTabContent: LayoutItem[] = [];

  ngOnInit(): void {
    if (this.formGroup) {
      this.controlMap = this.universalFormService.getControlMap(
        this.resourceName,
        this.formGroup,
        this.parentPath ? this.parentPath : undefined,
      );
    }
    this.loadJsonFile(this.layoutName).then((data) => {
      this.initLayout(data as Layout);
    });

    this.formGroup?.valueChanges.pipe(debounceTime(100)).subscribe(() => {
      this.refreshTabHasData();
    });

    this.refreshTabHasData();
  }

  private refreshTabHasData(): void {
    this.layout?.tabs.forEach((tab) => {
      tab.haveData = tab.items.some((item) => {
        const control = this.formGroup?.get(item.name);
        const value = control?.value;
        return (
          control &&
          (control?.value ?? null) !== null &&
          control.value !== '' &&
          (Array.isArray(value) ? value.length > 0 : typeof value === 'object' ? Object.keys(value).length > 0 : true)
        );
      });
    });
  }

  private initLayout(data: Layout): void {
    this.layout = data;
    if (this.layout.tabs.length > 0) {
      this.setActiveTab(this.layout.tabs[0].name);
    }
  }

  setActiveTab(tabName: string): void {
    this.activeTab = tabName;
    this.tabChange.emit(tabName);
    this.updateTabContent();
  }

  private updateTabContent(): void {
    this.currentTabContent = this.layout.tabs.find((tab) => tab.name === this.activeTab)?.items || [];
  }

  private async loadJsonFile(filename: string): Promise<unknown> {
    try {
      const response = await fetch(`assets/forms/${filename}.json`);
      if (!response.ok) {
        throw new Error(`Failed to load JSON file: ${response.statusText}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error loading JSON file:', error);
      return null;
    }
  }

  getPrettyValue(value: any): string {
    return this.universalFormService.getPrettyValue(value);
  }

  getPrettyText(text: string): string {
    return this.universalFormService.getPrettyText(text);
  }

  hasValue(field: ControlInfo | undefined): boolean {
    if (!field?.control) {
      return false;
    }

    const value = field.control.value;

    if (field.control instanceof FormGroup) {
      return Object.keys(field.control.controls).length > 0;
    }

    if (field.control instanceof FormArray) {
      return field.control.length > 0;
    }

    if (value === null || value === undefined) {
      return false;
    }

    if (typeof value === 'string') {
      return value.trim().length > 0;
    }

    if (typeof value === 'boolean' || typeof value === 'number') {
      return true;
    }

    return false;
  }
}
