import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { json2csv } from 'json-2-csv';

export interface Field {
  field: string;
  title: string;
}

@Component({
  selector: 'app-export-progress-modal',
  templateUrl: './export-progress-modal.component.html',
  styleUrls: ['./export-progress-modal.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class ExportProgressModal {
  @Input() totalItems: number = 0;
  @Input() itemsPerPage: number = 0;
  @Input() currentIndex: number = 0;
  @Input() totalPages: number = 0;
  @Input() data: any[] = [];
  @Input() fileName: string = '';
  @Input() isLimited: boolean = false;
  @Input() hardLimit: number = 2500;

  url: string = '';
  active: boolean = true;
  delimiter: {
    field: string;
    wrap: string;
    eol: string;
  } = {
    field: ',',
    wrap: '"',
    eol: '\r\n',
  };

  constructor(private activeModal: NgbActiveModal) {}

  get progress(): number {
    if (this.totalItems === 0) return 0;
    return Math.round((this.currentIndex / this.totalItems) * 100);
  }

  get isActive(): boolean {
    return this.active;
  }

  onCancel(): void {
    this.active = false;
    this.activeModal.dismiss();
  }

  generateCsv(fields: Field[]): void {
    const csvContent = json2csv(this.data, {
      keys: fields,
      delimiter: this.delimiter,
    });
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    this.url = URL.createObjectURL(blob);
    this.triggerDownload(this.url, this.fileName);
  }

  private triggerDownload(url: string, filename: string): void {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  get encodedEol(): string {
    return this.delimiter.eol.replace(/\r/g, '\\r').replace(/\n/g, '\\n');
  }
}
