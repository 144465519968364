import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgClass, NgFor } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { catchError, concatMap, map, Observable, of } from 'rxjs';
import { NoResultComponent } from '../../components/no-result/no-result.component';
import { LoadingComponent } from '../../components/loading/loading.component';
import { MapComponent } from '../../components/map/map.component';
import { MapService } from 'src/app/services/map/map.service';
import { Feature as OlFeature } from 'ol';
import { Geometry as OlGeometry } from 'ol/geom';
import { Feature, GeoJsonProperties, MultiPolygon, Polygon } from 'geojson';
import GeoJSON from 'ol/format/GeoJSON';
import * as turf from '@turf/turf';

type fnOnChange = (_: OlFeature) => void;

@Component({
  selector: 'app-map-by-location',
  standalone: true,
  imports: [FormsModule, MapComponent, NgFor, NgClass, NoResultComponent, LoadingComponent],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: MapByLocationControlValueAccessor, multi: true }],
  templateUrl: './map-by-location.component.html',
  styleUrl: './map-by-location.component.scss',
})
export class MapByLocationControlValueAccessor implements ControlValueAccessor, OnInit {
  @Input() inputAdresse = 'rue nationale marseille';

  @Output() emitCoordinates = new EventEmitter();

  http = inject(HttpClient);
  mapService = inject(MapService);

  //gestion manuelle des loading car pas de promesse (observable)
  loading = false;

  lieu: string = '';
  lieux?: NominatimTransform[];
  feature?: OlFeature;

  onChange: fnOnChange = (): void => {
    //do nothing
  };
  onTouch?: () => void;

  registerOnChange(fn: fnOnChange): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }
  writeValue(feature: OlFeature): void {
    this.feature = feature;
  }

  ngOnInit(): void {
    if (this.inputAdresse) {
      this.lieu = this.inputAdresse;
      this.onSubmitAddress();
    }
  }

  onSubmitGeoJson(): void {
    if (this.feature) {
      this.onChange(this.feature);
      this.emitCoordinates.emit(this.feature);
    }
  }

  tempArrayFeature: FeatureNominatim[] = [];
  uniqueNameArray = new Set<string>();
  requestCount = 0;

  //Fonction récursive pour récupérer les données de l'API Nominatim
  //Si on a plus de 10 résultats, après fusion, on arrête la recherche
  //Si on a moins de 10 résultats, après fusion, et qu'on a 50 résultats (avant fusion), on continue la recherche
  //On limite à 3 requêtes
  makeRequest(ids: number[] = []): Observable<FeatureNominatim[]> {
    const url = `https://nominatim.openstreetmap.org/search?q=${this.lieu}&polygon_geojson=1&dedupe=0&format=geojson&countrycodes=fr&layer=address&limit=50`;
    const addIds = ids.join(',');
    const maxRequest = 3;

    return this.http.get(url + (addIds ? '&excludePlaceIds=' + addIds : '')).pipe(
      map((result) => {
        this.requestCount++;
        return (result as NominatimResponse).features;
      }),
      concatMap((response) => {
        this.tempArrayFeature.push(...response);
        response.forEach((f) => {
          this.uniqueNameArray.add(f.properties.display_name);
        });
        if (response.length === 50 && this.uniqueNameArray.size < 10 && this.requestCount < maxRequest) {
          return this.makeRequest(this.tempArrayFeature.map((f) => f.properties.place_id));
        }
        return of([...this.tempArrayFeature]);
      }),
    );
  }

  onSubmitAddress(): void {
    this.tempArrayFeature = [];
    this.uniqueNameArray.clear();
    this.loading = true;
    this.makeRequest()
      .pipe(
        catchError(() => {
          this.loading = false;
          return of(this.tempArrayFeature);
        }),
        map((result) => {
          //regrouper tous les résultats qui ont le même display_name ensemble
          const resultGrouped = result.reduce((acc: NominatimTransform[], curr) => {
            const searchDisplayName = acc.find((a) => a.display_name === curr.properties.display_name);
            if (!searchDisplayName || curr.properties.type === 'administrative') {
              acc.push({ display_name: curr.properties.display_name, features: [curr] });
            } else {
              acc.find((a) => a.display_name === curr.properties.display_name)?.features.push(curr);
            }
            return acc;
          }, []);

          return resultGrouped;
        }),
      )
      .subscribe((result) => {
        this.loading = false;
        this.lieux = result;
        this.selectedFeaturesIndex.length = 0;
        this.reinitMap();
      });
  }

  reinitMap(): void {
    this.mapService.removeFeaturesOfLayer('lieu');
  }

  selectedFeaturesIndex: number[] = [];

  selectFeature(index: number, event: MouseEvent): void {
    this.reinitMap();
    if (!event.ctrlKey && !event.metaKey) {
      this.selectedFeaturesIndex = [];
    }
    if (this.selectedFeaturesIndex.includes(index)) {
      this.selectedFeaturesIndex = this.selectedFeaturesIndex.filter((f) => f !== index);
    } else {
      this.selectedFeaturesIndex.push(index);
    }
    this.showFeatures();
  }

  showFeatures(): void {
    //on récupère tous les éléments selectionnés par l'user
    const nominatimFeatures = this.lieux?.filter((_, index) => this.selectedFeaturesIndex.includes(index));

    //On les places dans un même array
    const featuresList = nominatimFeatures?.reduce((acc: FeatureNominatim[], curr) => {
      acc.push(...curr.features);
      return acc;
    }, []);

    if (!featuresList?.length) return;

    //Création d'un buffer 20m autour des features (qui ne sont pas des polygones)
    const featurePolygon = this.bufferFeatures(featuresList);

    const unionPolygon = this.unionFeatures(
      featurePolygon.filter((f) => f !== undefined) as Feature<Polygon | MultiPolygon, GeoJsonProperties>[],
    );

    const simplifiedPolygon = unionPolygon && turf.simplify(unionPolygon, { tolerance: 0.00005, highQuality: true });

    const featureOl = this.convertToFeatureOl(simplifiedPolygon);

    this.feature = featureOl;

    this.mapService.addFeature(featureOl, 'lieu');
    this.mapService.zoomToFeature(featureOl, 500, 18);
  }

  bufferFeatures(featuresList: FeatureNominatim[]): (Feature<Polygon | MultiPolygon, GeoJsonProperties> | undefined)[] {
    return featuresList.map((f: FeatureNominatim) => {
      if (f.geometry.type === 'Polygon') {
        return turf.buffer(f as any, 1, { units: 'meters' });
      }
      const bufferedPolygon = turf.buffer(f as any, 20, { units: 'meters' });
      return bufferedPolygon;
    });
  }

  unionFeatures(
    featureList: Feature<Polygon | MultiPolygon, GeoJsonProperties>[],
  ): Feature<Polygon | MultiPolygon> | null {
    return featureList.length > 1 ? turf.union(turf.featureCollection(featureList as any)) : featureList[0];
  }

  convertToFeatureOl(
    geoJson: Feature<Polygon | MultiPolygon, GeoJsonProperties> | null | undefined,
  ): OlFeature<OlGeometry> {
    const format = new GeoJSON();
    return format.readFeature(geoJson, {
      featureProjection: 'EPSG:3857',
    });
  }
}

interface NominatimResponse {
  type: string;
  licence: string;
  features: FeatureNominatim[];
}

interface FeatureNominatim {
  type: 'Point' | 'MultiPoint' | 'LineString' | 'MultiLineString' | 'Polygon' | 'MultiPolygon';
  properties: Properties;
  bbox: number[];
  geometry: Geometry;
}

interface Properties {
  place_id: number;
  osm_type: string;
  osm_id: number;
  place_rank: number;
  category: string;
  type: string;
  importance: number;
  addresstype: string;
  name: string;
  display_name: string;
}

interface Geometry {
  type: string;
  coordinates: number[][];
}

interface NominatimTransform {
  display_name: string;
  features: FeatureNominatim[];
}
