import { Component, inject, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UniversalFormService } from 'src/app/services/universal-form/universal-form.service';
import { UniversalFormLayoutComponent } from '../universal-form-layout/universal-form-layout.component';
import { NameOpenApiObject } from 'src/assets/openapi/metariscObjectName';

@Component({
  selector: 'app-universal-form-layout-page',
  templateUrl: './universal-form-layout-page.component.html',
  styleUrls: ['./universal-form-layout-page.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, UniversalFormLayoutComponent],
})
export class UniversalFormLayoutPageComponent implements OnInit {
  private universalFormService = inject(UniversalFormService);
  formGroup: FormGroup | undefined;
  resourceName: NameOpenApiObject = 'Clicdvcrem';

  ngOnInit(): void {
    this.formGroup = this.universalFormService.getFormGroup(this.resourceName);
  }
}
