import { Component, Input } from '@angular/core';
import { RecapDossierComponent } from '../../recap-dossier/recap-dossier.component';
import { Dossier } from '@metarisc/metarisc-js/lib/model/Dossier';

@Component({
  selector: 'app-list-dossier-item',
  templateUrl: './list-dossier-item.component.html',
  styleUrls: ['./list-dossier-item.component.scss'],
  standalone: true,
  imports: [RecapDossierComponent],
})
export class ListDossierItemComponent {
  @Input() dossiers: Dossier[] = [];
}
