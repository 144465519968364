export enum TypeDossier {
  DECI = 'DECI',
  ERP = 'ERP',
}

export interface Enveloppe {
  id: string;
  titre: string;
  nb_dossiers: number;
}

export enum EtatDeci {
  DOSSIER_OUVERT_APPLIQUE = 'Dossier ouvert appliqué',
  DOSSIER_OUVERT = 'Dossier ouvert',
  DOSSIER_PROJET_FERME_APPLIQUE = 'Dossier projet fermé appliqué',
  DOSSIER_FERME_APPLIQUE = 'Dossier fermé appliqué',
}

export enum EtatErp {
  EN_ATTENTE_DE_TRAITEMENT = 'En attente de traitement',
  PRESENCE_AVIS_RAPPORTEUR = "Présence d'un avis de rapporteur",
  VALIDE = 'Validé',
  PRESENCE_AVIS_COMMISSION = "Présence d'un avis de commission",
}

export enum TypePei {
  PIBI = 'PIBI',
  PENA = 'PENA',
}

export enum Disponibilite {
  DISPONIBLE = 'DISPONIBLE',
  INDISPONIBLE = 'INDISPONIBLE',
  REFORME = 'RÉFORMÉ',
}

export enum Performance {
  PERFORMANT = 'Performant',
  PERFORMANCE_RESTREINTE = 'Performance restreinte',
  PERFORMANCE_INCONNUE = 'Performance inconnue',
  PERFORMANCE_NULL = 'Performance nulle',
}
