import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilitairesService {
  constructor() {}

  scrollTop(): void {
    const rightSide = document.getElementById('right-side');
    rightSide?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  formatDate(date: Date): string {
    return date.toISOString().replace(/\.\d{3}Z$/, 'Z');
  }

  removeNullProperties<T extends Record<string, any>>(
    obj: T,
    exclude?: [keyof T],
  ): RecursivelyReplaceNullWithUndefined<T> {
    return Object.fromEntries(
      Object.entries(obj)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([key, value]) => value !== null || exclude?.includes(key)) // Filtre les valeurs nulles
        .map(([key, value]) => [
          key,
          value && typeof value === 'object' && !Array.isArray(value)
            ? this.removeNullProperties(value) // Appel récursif pour les sous-objets
            : value,
        ]),
    ) as RecursivelyReplaceNullWithUndefined<T>;
  }
  filterDatas<T extends object>(data: T, keys: Array<keyof T>): Partial<T> {
    const result: Partial<T> = {};
    keys.forEach((key) => {
      if (key in data) {
        result[key as keyof T] = data[key as keyof T];
      }
    });
    return result;
  }
}
//Permet de convertir le type de sorti "null" en "undefined" dans un objet
//Un objet {a:1, b:null} devra avoir un typage d'entrée {a: number, b?: value | null}
//Et retournera en valeur {a:1} sans problème de typage
type RecursivelyReplaceNullWithUndefined<T> = T extends null
  ? undefined
  : {
      [K in keyof T]: T[K] extends (infer U)[]
        ? RecursivelyReplaceNullWithUndefined<U>[]
        : RecursivelyReplaceNullWithUndefined<T[K]>;
    };
