import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Collection } from '@metarisc/metarisc-js';
import { Subject } from 'rxjs';

export type Univers = {
  libelle: string;
  endpoint: string;
  search_method: string;
  filters: FilterInput[];
  redirect_url?: string;
};

export type FilterInput =
  | 'trierPar'
  | 'periode'
  | 'geojson'
  | 'est_disponible'
  | 'domanialite'
  | 'type_pei'
  | 'type_activite'
  | 'type_commission'
  | 'type_dossier'
  | 'affecte'
  | 'erp_lie'
  | 'pei_lie'
  | 'statut'
  | 'avis'
  | 'locaux_sommeil'
  | 'references_exterieures'
  | 'workflow_actif'
  | 'contenu'
  | 'a_visiter_en'
  | 'site_geographique';

export type FilterOutput =
  | 'trierPar'
  | 'periode'
  | 'geojson'
  | 'est_disponible'
  | 'domanialite'
  | 'type'
  | 'type_activite'
  | 'affecte'
  | 'erp'
  | 'pei'
  | 'statut'
  | 'avis_exploitation'
  | 'presence_locaux_sommeil'
  | 'references_exterieures'
  | 'workflow_actif'
  | 'contenu'
  | 'a_visiter_en'
  | 'site_geographique';

@Injectable({
  providedIn: 'root',
})
export class RechercheService {
  collection?: CollectionAny;
  collectionChange = new Subject();
  reloadSearch = new Subject<string>();

  constructor(private router: Router) {}

  setCollection(collection: CollectionAny): void {
    this.collection = collection;
    this.collectionChange.next(undefined);
  }

  getCollection(): CollectionAny | undefined {
    return this.collection;
  }

  updateQueryParams(query_params: { [param: string]: string | string[] }): void {
    this.router.navigate([], { queryParams: query_params });
  }
}
type CollectionAny = Collection<any>;
