@if (placeholder || dossier) {
  <div>
    @if (placeholder) {
      @for (iPlaceholder of placeholder | nbToArray; track $index) {
        <div class="dossier d-flex align-items-start gap-2 frosted-glass link p-2 mb-2">
          <div class="status"></div>
          <div class="row flex-grow-1 justify-content-between gx-1_5 gy-1_5">
            <div class="col-lg-8 placeholder-glow">
              <div class="placeholder ff-semi-bold fs-5 w-50"></div>
              <div class="placeholder mt-1_5 w-100"></div>
              <div class="placeholder-glow d-flex flex-wrap gap-2 mt-1_5">
                <span class="placeholder fs-3 w-25"></span>
                <span class="placeholder fs-4"></span>
              </div>
              <div class="placeholder-glow d-flex flex-wrap gap-1 mt-1_5">
                <span class="placeholder w-25"></span>
                <span class="placeholder w-25"></span>
              </div>
            </div>
            <div class="col-lg-4 placeholder-glow">
              <div
                class="d-flex flex-wrap flex-lg-column align-items-center align-items-lg-end text-end gap-1_5 gap-lg-2_5"
              >
                <span class="placeholder w-50 lg-w-75"></span>
                <span class="placeholder fs-5 w-25"></span>
              </div>
            </div>
          </div>
        </div>
      }
    } @else {
      <div class="dossier d-flex align-items-start gap-2 frosted-glass card-link p-2">
        <div class="status" [ngClass]="{ active: dossier.statut === 'OUVERT' }"></div>
        <a
          class="row flex-grow-1 justify-content-between gx-1_5 gy-1_5 inherit-color"
          [routerLink]="'/dossier/' + this.dossier.id"
        >
          <div class="col-lg-7">
            <div class="ff-semi-bold fs-5 link-dossier">
              {{ dossier.objet || 'Sans objet' }}
            </div>
            @if (dossier.est_archive) {
              <div class="d-flex flex-wrap gap-1_5 mt-1_5">
                <app-badge-dossier-archive></app-badge-dossier-archive>
              </div>
            }
            @if (dossierPei) {
              <div class="d-flex flex-wrap gap-1_5 mt-1_5 link-dossier">
                <app-badge-deci-type [data]="dossierPei.pei"></app-badge-deci-type>
              </div>
            }
            @if (dossierErp) {
              <div class="d-flex flex-wrap gap-1_5 mt-1_5">
                <div class="link-dossier">
                  <app-badge-erp
                    [clickable]="true"
                    [list]="[
                      {
                        id: dossierErp.erp.id || 'noid',
                        activite: dossierErp.erp.descriptif_technique?.analyse_risque?.activite_principale || '',
                        name: dossierErp.erp.descriptif_technique?.libelle || 'ERP sans nom',
                      },
                    ]"
                  ></app-badge-erp>
                </div>
                @if (dossier.enveloppe) {
                  <app-badge-enveloppe [enveloppe]="dossier.enveloppe"></app-badge-enveloppe>
                }
                @if (dossierErp.passage_en_commission) {
                  <app-badge-passage-commission [passage]="dossierErp.passage_en_commission"></app-badge-passage-commission>
                }
              </div>
            }
            <div class="d-flex flex-wrap gap-1_5 mt-1_5">
              <div class="badge type-dossier"><i class="bi bi-file-earmark-text"></i> {{ getPrettyLabel(dossier.type || '') }}</div>
              @if (dossierErp && dossierErp.numero_urbanisme) {
                <div class="badge numero-urbanisme"><i class="bi bi-buildings"></i> {{ dossierErp.numero_urbanisme }}</div>
              }
            </div>
            <div class="d-flex flex-wrap gap-1_5 mt-1_5">
              @if (dossierErp) {
                @for (workflow of dossierErp.workflows_actifs; track $index) {
                  <div class="badge badge-workflow"><i class="bi bi-bar-chart-steps"></i> {{ workflow }}</div>
                }
              }
            </div>
            @if (dossier.tags && dossier.tags.length) {
              <div class="d-flex flex-wrap gap-1_5 mt-1_5">
                @for (tag of dossier.tags; track $index) {
                  @if (tag.message) {
                    <app-tag [tag]="tag.message"></app-tag>
                  }
                }
              </div>
            }
          </div>
          <div class="col-lg-3">
            <div
              class="d-flex flex-wrap flex-lg-column align-items-center align-items-lg-end text-end gap-1_5 gap-lg-2_5"
            >
              <span class="text-muted fs-6">
                {{ formatDate() }}
                @if (dossier.createur) {
                  <span class="text-info">
                    par
                    @if (dossier.createur.first_name || dossier.createur.last_name) {
                      {{ dossier.createur.first_name || '' }} {{ dossier.createur.last_name || '' }}
                    } @else {
                      import automatique
                    }
                  </span>
                }
              </span>
              @if (dossier.application_utilisee_nom) {
                <app-via-app [app]="dossier.application_utilisee_nom"></app-via-app>
              }
              @if (dossierErp && dossierErp.affectations.length) {
                <div class="d-flex flex-column w-100">
                  <span class="ff-semi-bold">Groupe de travail : </span>
                  <app-avatars-affectations [affectations]="dossierErp.affectations"></app-avatars-affectations>
                </div>
              }
              @if (dossierErp && dossierErp.avis) {
                <app-badge-avis-exploitation [avis]="dossierErp.avis"></app-badge-avis-exploitation>
              }
            </div>
          </div>
        </a>
      </div>
    }
  </div>
}
