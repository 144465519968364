import { DatePipe, NgClass, NgIf } from '@angular/common';
import { Component, Pipe, PipeTransform, OnDestroy, inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Utilisateur } from '@metarisc/metarisc-js/src/model/Utilisateur';
import { MetariscService } from 'src/app/services/metarisc/metarisc.service';
import { LoadingComponent } from 'src/app/shared/components/loading/loading.component';
import { NoResultComponent } from 'src/app/shared/components/no-result/no-result.component';
import { FicheDossierService } from '../../services/fiche-dossier/fiche-dossier.service';
import { UtilitairesService } from 'src/app/services/utilitaires/utilitaires.service';
import { UniversalFormService } from 'src/app/services/universal-form/universal-form.service';
import { Subscription } from 'rxjs';
import { ToastNotificationService } from 'src/app/services/toast-notification/toast-notification.service';
import { SireneAI } from '@metarisc/metarisc-js/lib/sirene-ai';
import { ChatCompletionMessage, ChatCompletionMessageParam } from 'openai/resources/chat';

export interface AIResponse {
  id: string;
  object: string;
  created: number;
  model: string;
  choices: ChoiceAI[];
  usage: UsageAI;
  prompt_logprobs: any;
}

export interface ChoiceAI {
  index: number;
  message: MessageAI;
  logprobs: any;
  finish_reason: string;
  stop_reason: any;
}

export interface MessageAI {
  role?: string;
  reasoning_content?: any;
  content: string;
  tool_calls?: any[];
  date?: Date;
  me?: boolean;
  name?: string;
  refusal?: string;
}

export interface UsageAI {
  prompt_tokens: number;
  total_tokens: number;
  completion_tokens: number;
  prompt_tokens_details: any;
}

@Pipe({
  name: 'reverse',
  standalone: true,
})
export class ReversePipe implements PipeTransform {
  transform(value?: any[]): any[] | undefined {
    if (!value) return undefined;
    return [...value].reverse();
  }
}

@Component({
  selector: 'app-fil-rouge',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgClass,
    DatePipe,
    NoResultComponent,
    LoadingComponent,
    ReversePipe,
  ],
  templateUrl: './fil-ai.component.html',
  styleUrl: './fil-ai.component.scss',
})
export class FilAiComponent implements OnDestroy, OnInit {
  metaService = inject(MetariscService).getMetarisc();
  dossierService = inject(FicheDossierService);
  utilService = inject(UtilitairesService);
  ufs = inject(UniversalFormService);
  notif = inject(ToastNotificationService);

  utilisateur?: Utilisateur;
  private readonly MAX_CONTEXT_MESSAGES = 10; // Nombre maximum de messages à inclure dans le contexte

  loading?: Promise<unknown>;

  msgModel: MessageAI[] = [];

  messages: MessageAI[] = [];
  formMessage = this.ufs.getFormGroup('PostFilRougeDossierDossiers');

  timer$?: Subscription;

  loadingPost?: Promise<unknown>;

  private progressiveTextInterval?: any;

  displayProgressiveText(bloc: HTMLDivElement, text: string, delay: number = 100): void {
    if (!bloc) return;
    const element = bloc;

    // Stop the current interval if it exists
    if (this.progressiveTextInterval) {
      clearInterval(this.progressiveTextInterval);
      element.innerHTML += '<hr style="margin:30px 0">'; // Add two line breaks
    } else {
      element.innerHTML = ''; // Clear the element if no interval exists
    }

    let index = 0;

    this.progressiveTextInterval = setInterval(() => {
      if (index < text.length) {
        element.innerHTML += text[index];
        index++;
      } else {
        clearInterval(this.progressiveTextInterval);
        this.progressiveTextInterval = undefined;
      }
    }, delay);
  }

  ngOnInit(): void {
    this.metaService.utilisateurs?.getUtilisateursMoi().then((response) => {
      this.utilisateur = response.data;
    });
  }

  formatMessage(message: MessageAI): MessageAI {
    message.content = message.content.replace(/\n/g, '<br>');
    message.date = new Date();
    return message;
  }

  formatMessageAI(message: ChatCompletionMessage): MessageAI {
    return {
      content: message.content || '',
      date: new Date(),
      me: message.role !== 'assistant',
      refusal: message.refusal || undefined,
    };
  }

  private getRelevantContext(): ChatCompletionMessageParam[] {
    // On garde toujours le prompt système avec les documents de référence
    const systemMessage: ChatCompletionMessageParam = {
      role: 'system',
      content: SireneAI.SYSTEM_PROMPT,
    };

    // On prend les derniers messages de la conversation
    const recentMessages = this.messages.slice(-this.MAX_CONTEXT_MESSAGES);

    // On convertit les messages en format pour l'API
    const conversationMessages: ChatCompletionMessageParam[] = recentMessages.map((msg) => ({
      role: msg.me ? 'user' : 'assistant',
      content: msg.content,
    }));

    return [systemMessage, ...conversationMessages];
  }

  addMessage(): void {
    const userMsg: MessageAI = {
      content: this.formMessage.get('message')?.value,
      date: new Date(),
      me: true,
    };
    this.messages = [...this.messages, userMsg];

    // On utilise maintenant getRelevantContext() au lieu de tout le contexte
    const messagesForAI = this.getRelevantContext();

    this.loadingPost = this.metaService.sirene_ai
      ?.init()
      ?.chat.completions.create({
        model: 'mistral-nemo-instruct-2407',
        messages: messagesForAI,
        max_tokens: 512,
        temperature: 0.3,
        top_p: 1,
        presence_penalty: 0,
      })
      .then((response) => {
        const msgResponse = response.choices[0].message;
        this.messages = [...this.messages, this.formatMessageAI(msgResponse)];
        this.formMessage.reset('');
      })
      .catch((error) => {
        this.notif.error("Erreur lors de l'envoi du message (" + error.message + ')');
      })
      .finally(() => {
        this.loadingPost = undefined;
      });
  }

  displayDate(index: number): boolean {
    if (this.messages) {
      const nextMessage = this.messages[index + 1];
      if (!nextMessage) return true;
    }
    return true;
  }

  ngOnDestroy(): void {
    this.timer$?.unsubscribe();
  }
}
