import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PassageCommission } from '@metarisc/metarisc-js/lib/model/PassageCommission';

@Component({
  selector: 'app-badge-passage-commission',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './badge-passage-commission.component.html',
  styleUrls: ['./badge-passage-commission.component.scss'],
})
export class BadgePassageCommissionComponent {
  @Input() passage!: PassageCommission;
}
