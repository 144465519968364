import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { MetariscService } from '../services/metarisc/metarisc.service';

export const authGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const metariscService = inject(MetariscService);
  const router = inject(Router);

  authService.setMetariscToken();
  const access_token = authService.getAccessToken();
  const refresh_token = authService.getRefreshToken();

  if (!access_token && refresh_token) {
    metariscService
      .getMetarisc()
      .refreshToken()
      .then((response) => {
        authService.setRefreshResponseCookie(response);
        authService.setMetariscToken();
      })
      .catch(() => {
        authService.logout();
      });
  }
  if (!access_token && !refresh_token) {
    return router.navigateByUrl('/access');
  }

  return true;
};
