
  <div class="col-12">
    <select class="form-select" (change)="onChange($event)">
      <option value="">Sélectionner une requête</option>
      @for (item of dashboardList; track item.command) {
        <option [value]="item.command">
          {{ item.title }}
        </option>
      }
    </select>
  </div>

