import { NgClass } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';

export type DescriptionErp = {
  icon?: string;
  id: string;
  activite?: string;
  name?: string;
  url?: string;
};

@Component({
  selector: 'app-badge-erp',
  templateUrl: './badge-erp.component.html',
  styleUrls: ['./badge-erp.component.scss'],
  standalone: true,
  imports: [NgClass, SvgIconComponent, RouterLink],
})
export class BadgeErpComponent implements OnChanges {
  @Input() list?: DescriptionErp[];
  @Input() clickable = false;

  ids: number[] = [];

  constructor(private router: Router) {}

  ngOnChanges(): void {
    this.list?.forEach((e) => {
      e.activite = e.activite?.split(' - ')[0];
      e.url = e.activite
        ? `assets/images/types-erp/Types=${e.activite}.svg`
        : 'assets/images/types-erp/dash-square.svg';
      this.ids.push(this.getRandomId());
    });
  }

  getRandomId(): number {
    return Math.round((Math.random() * 36) ** 12);
  }

  goToFicheErp(id: string, event: MouseEvent): void {
    if (this.clickable) {
      event.stopPropagation();
      this.router.navigateByUrl('/erp/fiche-erp/' + id);
    }
  }

  isFirst(index: number): boolean {
    return !!this.list && this.list.length > 0 && index === 0;
  }

  isLast(index: number): boolean {
    return !!this.list && this.list.length > 0 && index === this.list.length - 1;
  }
}
