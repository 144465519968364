import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AvisExploitationEnum } from '@metarisc/metarisc-js/lib/model/ERP';
import { AvisEnum } from '@metarisc/metarisc-js/lib/model/PassageCommissionDossier';

@Component({
  selector: 'app-badge-avis-exploitation',
  templateUrl: './badge-avis-exploitation.component.html',
  styleUrls: ['./badge-avis-exploitation.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class BadgeAvisExploitationComponent {
  @Input() avis!: AvisExploitationEnum | AvisEnum;

  isFavorable(): boolean {
    return this.avis === AvisExploitationEnum.FAVORABLE;
  }
}
