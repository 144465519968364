
    @if (dateOnly) {
        <input
            type="date"
            class="form-control"
            [formControl]="dateTimeControl"
            (blur)="onTouched()"
        >
    } @else {
        <input
            type="datetime-local"
            class="form-control"
            [formControl]="dateTimeControl"
            (blur)="onTouched()"
        >
    }
  