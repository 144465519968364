import { Injectable } from '@angular/core';
import { FormGroup, Validators, ValidatorFn, AbstractControl, FormControl, FormArray } from '@angular/forms';
import resourcesPOST from '../../../assets/openapi/metarisc.json';
import { NameOpenApiObject } from 'src/assets/openapi/metariscObjectName';
export interface JsonSchema {
  type?: string;
  title?: string;
  description?: string;
  properties?: { [key: string]: JsonSchema };
  required?: string[];
  items?: JsonSchema;
  maxItems?: number;
  minLength?: number;
  maxLength?: number;
  minimum?: number;
  maximum?: number;
  pattern?: string;
  enum?: any[];
  format?: string;
  oneOf?: JsonSchema[] | undefined;
  allOf?: JsonSchema[] | undefined;
  anyOf?: JsonSchema[] | undefined;
  $ref?: string;
  nullable?: boolean;
}

export enum ControlType {
  text = 'text',
  number = 'number',
  checkbox = 'checkbox',
  date = 'date',
  datetime = 'datetime',
  select = 'select',
  array = 'array',
  placeholder = 'placeholder',
  keyvalue = 'keyvalue',
  object = 'object',
}

export interface ControlInfo {
  key: string;
  fullKey: string;
  label: string;
  placeholder?: string;
  description?: string;
  control: AbstractControl;
  type: ControlType;
  items: JsonSchema;
  maxItems?: number;
  itemsControlInfo: { [key: string]: ControlInfo };
  aditionnalControls?: ControlInfo[];
  childs: ControlInfo[];
  options?: string[];
  pattern?: string;
  minimum?: number;
  maximum?: number;
  minLength?: number;
  maxLength?: number;
  required?: boolean;
}

export type ResourceAttribute = {
  key: string;
  name: string;
  type: string;
  format: string;
  minimum: number;
  maximum: number;
  enum: string[];
  description: string;
  maxLength: number;
  pattern: string;
  allOf: ResourceAttribute[];
  items?: JsonSchema;
  additionalProperties?: ResourceAttribute;
};

@Injectable({
  providedIn: 'root',
})
export class UniversalFormService {
  objects: any;

  constructor() {
    this.loadObjectsJsonFile().then((data) => {
      this.objects = data;
    });
  }

  getFormGroup(resource: NameOpenApiObject, exclude?: string[]): FormGroup {
    const selectedResource = resourcesPOST.find((resourceData: any) => resourceData.name === resource);
    const schema = selectedResource?.schema as unknown as JsonSchema;
    if (schema) {
      if (exclude) {
        const form = this.jsonSchemaToFormGroup(resource, schema, '');
        exclude.forEach((key) => {
          form.removeControl(key);
        });
        return form;
      } else {
        return this.jsonSchemaToFormGroup(resource, schema, '');
      }
    }
    return {} as FormGroup;
  }

  createItemFormGroup(item: ControlInfo): FormGroup {
    const formGroup = new FormGroup({});
    Object.keys(item.itemsControlInfo).forEach((key) => {
      const ctrl = item.itemsControlInfo[key] as ControlInfo;
      const newItemsControlInfo = { ...ctrl };
      const control = new FormControl(null, this.getControlInfoValidators(newItemsControlInfo));
      formGroup.addControl(key, control);
    });
    return formGroup;
  }

  getDictionary(resource: string): any[] {
    const fields: any[] = [];
    const selectedResource = resourcesPOST.find((resourceData: any) => resourceData.name === resource);

    const processSchema = (currentSchema: JsonSchema, parentKey: string = ''): void => {
      if (currentSchema.properties) {
        Object.entries(currentSchema.properties).forEach(([key, prop]): void => {
          const fullKey = parentKey ? `${parentKey}.${key}` : key;
          if (!prop.type && !prop.allOf) {
            prop.type = 'string';
          }
          fields.push({
            key: fullKey,
            ...prop,
            required: currentSchema.required?.includes(key) || false,
          });
          // Traitement des propriétés imbriquées
          if (prop.allOf) {
            prop.allOf.forEach((subSchema) => {
              processSchema(subSchema, fullKey);
            });
          }
          if (prop.type === 'object') {
            processSchema(prop as JsonSchema, fullKey);
          }
          // Traitement des tableaux
          if (prop.type === 'array' && prop.items) {
            const arrayItemKey = `${fullKey}`;
            if (prop.items.properties) {
              Object.entries(prop.items.properties).forEach(([itemKey, itemProp]) => {
                // Traitement des enfants d'un array
                fields.push({
                  key: `${arrayItemKey}.${itemKey}`,
                  ...itemProp,
                  required: prop.items?.required?.includes(itemKey) || false,
                });
                // Traitement récursif des propriétés des items
                if (itemProp.type === 'object' || itemProp.properties) {
                  processSchema(itemProp as JsonSchema, `${arrayItemKey}.${itemKey}`);
                }
                if (itemProp.allOf) {
                  itemProp.allOf.forEach((subSchema) => {
                    processSchema(subSchema, `${arrayItemKey}.${itemKey}`);
                  });
                }
              });
            }
          }
        });
      }

      ['allOf', 'anyOf', 'oneOf'].forEach((combiner) => {
        const schemaArray = currentSchema[combiner as keyof JsonSchema];
        if (Array.isArray(schemaArray)) {
          schemaArray.forEach((subSchema: JsonSchema) => {
            processSchema(subSchema, parentKey);
          });
        }
      });
    };

    if (selectedResource?.schema) {
      processSchema(selectedResource?.schema as unknown as JsonSchema);
    }
    return fields;
  }

  getSchema(resource: string): JsonSchema {
    const selectedResource = resourcesPOST.find((resourceData: any) => resourceData.name === resource);
    return selectedResource?.schema as unknown as JsonSchema;
  }

  getResourceList(): string[] {
    return resourcesPOST.map((resource) => {
      return resource.name;
    });
  }

  getPrettyText(text: string): string {
    if (!text) {
      return '';
    }
    // On ne garde que le dernier terme pour les clés composées (ex: commission.commission_date)
    text = text.substring(text.lastIndexOf('.') + 1);

    // TODO: charger un "dictionnaire depuis un fichier"
    const keyValue: { [key: string]: string } = {
      date_debut: 'Date de début',
      date_de_fin: 'Date de fin',
      commission_date: 'Date de commission',
      avis_de_commission: 'Avis de la commission',
      presence_obligatoire: 'Présence obligatoire',
      mesures_complementaires: 'Mesures complémentaires',
      derogations: 'Dérogations',
      prenom: 'Prénom',
      telephone_fixe: 'Téléphone fixe',
      telephone_portable: 'Téléphone portable',
      telephone_fax: 'Fax',
      civilite: 'Civilité',
      societe: 'Société',
      date_du_controle: 'Date du contrôle',
      liste_anomalies: 'Liste des anomalies',
      essais_engin_utilise: "Engin d'éssai utilisé",
      title: 'Titre',
      role: 'Rôle',
      reference: 'Référence',
      ajouter_activites_secondaire: 'Ajouter une activité secondaire',
      ajouter_degagement_normaux: 'Ajouter un dégagement normal',
      ajouter_degagements_accessoires: 'Ajouter un dégagement accessoire',
      ajouter_degagement_supplementaires: 'Ajouter un dégagement supplémentaire',
      ajouter_prescriptions: 'Ajouter une prescription',
      numero_urbanisme: "Numéro d'urbanisme",
      numero_urbanisme_date: "Date du numéro d'urbanisme",
      liee_a_l_exploitation: "Liée à l'exploitation",
      liee_a_l_amelioration: "Liée à l'amélioration",
      rappel_reglementaire: 'Rappel réglementaire',
      presence_erp_ou_tiers_superpose: 'Présence ERP ou tiers superposé',
      batiment_etages_en_superstructure: 'Bâtiment étages en superstructure',
      batiment_etages_en_infrastructure: 'Bâtiment étages en infrastructure',
      isolement_superpose_realise_degre_cf: 'Isolement superposé réalisé degré CF',
      isolement_superpose_realise_degre_cf_autre_precision: 'Isolement superposé réalisé degré CF autre précision',
      isolement_superpose_informations_complementaires: 'Isolement superposé (informations complémentaires)',
      r143_20: 'R. 143-20 (ERP ne correspondant à aucun type)',
      enfouissement: 'Dégagements de locaux installés en sous-sol',
      degagement_enfouissement_informations_complementaires: 'Dégagements de locaux installés en sous-sol',
      activite_principale: 'Activité principale',
      type: 'Type',
      activite: 'Activité',
      activites_secondaire: 'Activités secondaires',
      categorie: 'Catégorie',
      groupement_etablissement: "Groupement d'établissement",
      tableau_des_effectifs: 'Tableau des effectifs',
      niveau: 'Niveau',
      local: 'Local',
      surface_totale: 'Surface totale',
      surface_accessible_au_public: 'Surface accessible au public',
      effectif_public: 'Effectif public',
      effectif_personnel: 'Effectif personnel',
      presence_locaux_sommeil: 'Présence de locaux à sommeil',
      presence_locaux_sommeil_au_dessus_r1: 'Présence de locaux à sommeil au dessus R+1',
      niveau_total: "Nombre total de niveaux de l'établissement",
      etablissement_de_plain_pied: 'Etablissement de plain pied',
      plancher_bas_du_dernier_niveau: 'Plancher bas du dernier niveau',
      plancher_bas_du_dernier_niveau_accessible_au_public: 'Plancher bas du dernier niveau accessible au public',
      voie_engin: 'Voie engin',
      voie_echelle: 'Voie échelle',
      nombre_facades_accessibles: 'Nombre de facades accessibles',
      informations_acces_facades: 'Informations accès facades',
      espace_libre: 'Espace libre',
      desserte_informations_complementaires: 'Desserte (informations complémentaires)',
      presence_erp_ou_tiers_contigus: 'Présence ERP ou tiers contigus',
      isolement_contigus_realise_degre_cf: 'Isolement contigus réalisé degré CF',
      isolement_contigus_realise_degre_cf_autre_precision: 'Isolement contigus réalisé degré CF autre précision',
      isolement_contigus_informations_complementaires: 'Isolement contigus (informations complémentaires)',
      air_libre: 'Aire libre',
      vis_a_vis: 'Vis à vis',
      isolement_vis_a_vis_informations_complementaires: 'Isolement vis à vis (informations complémentaires)',
      structure_sf: 'Structure SF',
      plancher_sf: 'Plancher SF',
      stabilite_au_feu_informations_complementaires: 'Stabilité au feu (informations complémentaires)',
      isolement_autre_description: 'Isolement autre description',
      construction_structures_description: 'Description des structures de construction',
      construction_couverture_description: 'Description de la construction de la couverture',
      construction_facades_description: 'Description de la construction des façades',
      type_cloisonnement: 'Type de cloisonnement',
      construction_distribution_interieure_informations_complementaires: 'Distribution intérieure',
      locaux: 'Locaux',
      nom_du_local: 'Nom du local',
      etage_du_local: 'Etage du local',
      type_de_risque: 'Type de risque',
      local_informations_complementaires: 'Local (informations complémentaires)',
      construction_conduits_et_gaines_description: 'Construction conduits et gaines description',
      construction_amenagements_interieurs_description: 'Construction amenagements intérieurs description',
      degagement_normaux: 'Dégagement normal',
      degagements_accessoires: 'Dégagements accessoires',
      nombre_degagements: 'Nombre de dégagements',
      nombre_unite_passage: "Nombre d'unité de passage totalisé",
      degagement_exigibles_informations_complementaires: 'Dégagement exigibles (informations complémentaires)',
      degagement_supplementaires: 'Dégagement supplémentaires',
      degagement_escaliers_description: 'Escaliers',
      degagement_ascenseurs_escaliers_mecaniques_trottoirs_roulants_description:
        'Ascenseurs, escaliers mécaniques, trottoirs roulants',
      presence_eas_ou_equivalents: 'Présence EAS ou équivalents',
      degagement_espaces_attente_securises_informations_complementaires: "Espaces d'attente sécurisés",
      degagement_tribunes_et_gradins_non_demontables_description: 'Tribunes et gradins non démontables',
      presence_desenfumage_mécanique: 'Présence désenfumage mécanique',
      presence_desenfumage_naturel: 'Présence désenfumage naturel',
      ventilation_desenfumage_informations_complementaires: 'Ventilation désenfumage (informations complémentaires)',
      groupe_electrogene: 'Groupe électrogène',
      batteries_accumulateurs_et_materiels_associes: 'Batteries accumulateurs et matériels associés',
      photovoltaique: 'Photovoltaïque',
      parafoudre: 'Parafoudre',
      electricite_eclairage_informations_complementaires: 'Electricité éclairage (informations complémentaires)',
      puissance_chaufferie: 'Puissance chaufferie',
      presence_gaz_chaufferie: 'Présence de gaz chaufferie',
      type_de_chauffage: 'Type de chauffage',
      chauffage_ventilation_informations_complementaires: 'Chauffage ventilation (informations complémentaires)',
      puissance_cuisine: 'Puissance cuisine',
      type_de_cuisine: 'Type de cuisine',
      presence_gaz_cuisine: 'Présence de gaz cuisine',
      systeme_extraction: 'Système extraction',
      risques_spéciaux_informations_complementaires: 'Risques spéciaux (informations complémentaires)',
      presence_extincteur: 'Présence extincteur',
      presence_ria: 'Présence RIA',
      deversoirs_ponctuels: 'Déversoirs ponctuels',
      elements_de_constructions_irrigues: 'Elements de constructions irrigués',
      presence_deci: 'DECI conforme',
      presence_colonnes_seches: 'Présence colonnes sèches',
      presence_colonnes_en_charge: 'Présence colonnes en charge',
      installation_extinction: 'Installation extinction',
      moyens_de_secours_informations_complementaires: 'Moyens de secours (informations complémentaires)',
      deci_description: 'Description du dispositif DECI',
      affichage_des_plans_intervention: "Affichage des plans d'intervention",
      tour_incendie: "Tour d'incendie",
      tremie_attaque: 'Trémie attaque',
      service_de_securite_incendie: 'Service de sécurité incendie',
      presence_pc_securite: 'Présence PC sécurité',
      service_de_securite_incendie_informations_complementaires:
        'Service de sécurité incendie (informations complémentaires)',
      systeme_de_securite_incendie: 'Système de sécurité incendie',
      type_ssi: 'Type SSI',
      type_alarme: 'Type alarme',
      temporisation_alarme_en_minutes: 'Temporisation alarme en minutes',
      desenfumage_commande_par_le_ssi: 'Désenfumage commande par le SSI',
      ligne_telephonique_reliee_au_cta: 'Ligne téléphonique reliée au CTA',
      autre_systeme_alerte: "Système d'alerte",
      systeme_securite_incendie_informations_complementaires:
        'Système de sécurité incendie (informations complémentaires)',
      defibrillateur: 'Défibrillateur',
      specificites_informations_complementaires: 'Spécificités (informations complémentaires)',
      observations: 'Observations',
      prise_de_note_interne: 'Prise de note interne',
      proposition_avis: 'Proposition avis',
      proposition_avis_observations: 'Proposition avis observations',
      facteur_dangerosite: 'Facteur dangerosité',
      documents_techniques: 'Documents techniques',
      libelle: 'Libellé',
      date_de_reception: 'Date de réception',
      date_de_consultation: 'Date de consultation',
      est_manquant: 'Est manquant',
      modules: 'Activer des modules',
      ajouter_modules: 'Ajouter un module',
    };

    const translatedText = keyValue[text];
    if (translatedText) {
      return translatedText;
    } else {
      // Si la clé n'est pas trouvée, on applique une mise en forme
      return text
        .split('_')
        .map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
        .join(' ');
    }
  }

  getPrettyValue(text: string): string {
    if (!text || typeof text !== 'string') {
      return '';
    }
    // TODO: charger un "dictionnaire depuis un fichier"
    const keyValue: { [key: string]: string } = {
      'deci:declaration_pei': 'Déclaration PEI',
      'erp:autorisation_de_travaux': 'Autorisation de travaux',
      'erp:visite_periodique': 'Visite périodique',
      'erp:permis_de_construire': 'Permis de construire',
      'erp:levee_de_prescriptions': 'Levée de prescriptions',
      'erp:changement_de_dus': 'Changement de DUS',
      'erp:salon_type_t': 'Salon type T',
      'erp:utilisation_exceptionnelle_de_locaux': 'Utilisation exceptionnelle de locaux',
      'erp:demande_d_implantation_cts_inferieur_6_mois': "Demande d'implantation CTS inférieur à 6 mois",
      'erp:demande_d_implantation_cts_superieur_6_mois': "Demande d'implantation CTS supérieur à 6 mois",
      'erp:derogation': 'Dérogation',
      'erp:etude_cahier_des_charges_type_t': 'Étude cahier des charges type T',
      'erp:levee_de_reserve': 'Levée de réserve',
      'erp:echeancier_de_travaux': 'Échéancier de travaux',
      'erp:cahier_des_charges_ssi': 'Cahier des charges SSI',
      'erp:etude_suite_a_un_avis_differe': 'Étude suite à un avis différé',
      'erp:visite_reception': 'Visite de réception',
      'erp:visite_avant_ouverture': 'Visite avant ouverture',
      'erp:visite_controle': 'Visite de contrôle',
      'erp:visite_inopinee': 'Visite inopinée',
      'erp:visite_chantier': 'Visite de chantier',
      'erp:analyse_de_risque': "Rapport d'étude",
      'erp:essais_visite': 'Essais réalisés en visite',
      'deci:controle_technique_pei': 'Contrôle technique PEI',
      POLICE: 'DIPN (Direction interdépartementale de la Police Nationale)',
      GENDARMERIE: 'Gendarmerie',
      MAIRE: 'Le maire ou son représentant',
      SIS: 'DDSIS ou son représentant',
      REGION: 'Région',
      DISP: 'DISP (Direction interrégionale de la sureté pénitentiaire)',
      ferme: 'Fermé',
      reprise_de_donnees: 'Reprise de données',
    };

    const translatedText = keyValue[text];
    if (translatedText) {
      return translatedText;
    } else {
      // Si la clé n'est pas trouvée, on applique une mise en forme
      return text
        .split('_')
        .map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
        .join(' ')
        .replace(':', ' ');
    }
  }

  jsonSchemaToFormGroup(resource: string, schema: JsonSchema, parent: string): FormGroup {
    const formGroup = new FormGroup({});
    if (schema.properties) {
      Object.keys(schema.properties).forEach((key) => {
        const propertySchema = schema.properties![key];
        if (parent) {
          parent = `${parent}.${key}`;
        } else {
          parent = key;
        }
        if (propertySchema.allOf && !propertySchema.nullable) {
          propertySchema.allOf.forEach((subSchema) => {
            const subFormGroup = this.jsonSchemaToFormGroup(resource, subSchema, parent);
            formGroup.addControl(key, subFormGroup);
          });
        }
        const control = this.createFormControl(
          resource,
          propertySchema,
          schema.required?.includes(key) || false,
          parent,
        );
        formGroup.addControl(key, control);
      });
    }

    // Déjà géré en amont
    if (schema.oneOf) {
      if (schema.oneOf.length === 1) {
        const subFormGroup = this.jsonSchemaToFormGroup(resource, schema.oneOf[0], parent);
        for (const control in subFormGroup.controls) {
          const currentControl = subFormGroup.get(control);
          if (currentControl) {
            formGroup.addControl(control, currentControl);
          }
        }
      } else {
        console.log('oneOf: Ne devrait pas être présent');
      }
    }
    // Déjà géré en amont
    if (schema.anyOf) {
      console.log('anyOf: Ne devrait pas être présent');
    }

    if (schema.allOf && !schema.nullable) {
      schema.allOf.forEach((subSchema) => {
        const subFormGroup = this.jsonSchemaToFormGroup(resource, subSchema, parent);
        for (const control in subFormGroup.controls) {
          const currentControl = subFormGroup.get(control);
          if (currentControl) {
            formGroup.addControl(control, currentControl);
          }
        }
      });
    }

    return formGroup;
  }

  getControls(resourceName: NameOpenApiObject, formGroup: FormGroup, parentPath: string): ControlInfo[] {
    const dictionary = this.getDictionary(resourceName);
    const result = this.parseDictionary(formGroup, dictionary, parentPath);
    return result;
  }

  getControlMap(
    resourceName: NameOpenApiObject,
    formGroup: FormGroup,
    parentPath: string = '',
  ): Map<string, ControlInfo> {
    const controls = this.getControls(resourceName, formGroup, parentPath);
    const result = this.buildControlMap(controls);
    return result;
  }

  // Utility while API doesn't allow null values
  cleanEmptyValues(value: any): any {
    if (value === null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0)) {
      return undefined;
    }

    if (Array.isArray(value)) {
      const filteredArray = value.map((item) => this.cleanEmptyValues(item)).filter((item) => item !== undefined);
      return filteredArray.length > 0 ? filteredArray : undefined;
    }

    if (typeof value === 'object') {
      const cleanedObj: any = {};
      let hasValidProperties = false;

      for (const key in value) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
          const cleanedValue = this.cleanEmptyValues(value[key]);
          if (cleanedValue !== undefined) {
            cleanedObj[key] = cleanedValue;
            hasValidProperties = true;
          }
        }
      }

      return hasValidProperties ? cleanedObj : undefined;
    }

    return value;
  }

  patchFormGroup(formGroup: FormGroup, data: any, resourceName: string, parent: string = '', exclude?: string[]): void {
    if (!data) return;
    Object.keys(data).forEach((key) => {
      if (exclude && exclude.includes(key)) {
        return;
      }
      let control = formGroup.get(key);
      const fullKey = parent ? `${parent}.${key}` : key;
      if (!control) {
        const dictionary = this.getDictionary(resourceName);
        const attribute = dictionary.find((attribute: any) => attribute.key === fullKey);
        let validator: ValidatorFn[] | null = null;
        if (attribute) {
          validator = this.getControlInfoValidators(attribute);
        } else {
          return;
        }
        if (data[key] !== null) {
          if (typeof data[key] === 'object') {
            control = new FormGroup({}, validator);
          } else {
            const parentSchema = dictionary.find((attribute: any) => attribute.key === parent);

            control = this.createFormControl(
              resourceName,
              attribute,
              parentSchema?.items?.required && parentSchema?.items?.required?.includes(key) ? true : false,
              parent,
            );
          }
          formGroup.addControl(key, control);
        } else {
          control = new FormControl(null, validator);
          formGroup.addControl(key, control);
        }
      }

      if (control instanceof FormGroup) {
        // Recursively patch nested FormGroup
        this.patchFormGroup(control, data[key], resourceName, fullKey);
      } else if (control instanceof FormArray) {
        // Clear existing array items
        while (control.length) {
          control.removeAt(0);
        }

        // Add new array items
        if (Array.isArray(data[key])) {
          data[key].forEach((item: any) => {
            if (typeof item === 'object') {
              // Create nested FormGroup for complex items
              const itemGroup = new FormGroup({});
              this.patchFormGroup(itemGroup, item, resourceName, fullKey);
              control.push(itemGroup);
            } else {
              // Push simple values directly
              control.push(new FormControl(item));
            }
          });
        }
      } else {
        // Patch simple form control
        control.patchValue(data[key]);
      }
    });
  }

  getOptions(resourceName: string, key: string): string[] {
    const dictionary = this.getDictionary(resourceName);
    const attribute = dictionary.find((attribute: any) => attribute.key === key);
    return attribute?.enum || [];
  }

  private buildControlMap(
    controls: ControlInfo[],
    map: Map<string, ControlInfo> = new Map(),
  ): Map<string, ControlInfo> {
    controls.forEach((control) => {
      // Add current control to map using its fullKey
      map.set(control.fullKey, control);

      // Recursively process child controls if they exist
      if (control.childs && control.childs.length > 0) {
        this.buildControlMap(control.childs, map);
      }
    });

    return map;
  }

  parseDictionary(formGroup: FormGroup, dictionary: any, parent: string): ControlInfo[] {
    const mappedControls: ControlInfo[] = [];
    dictionary.forEach((attribute: any) => {
      let control!: AbstractControl;

      if (parent) {
        const realKey = attribute.key.replace(parent + '.', '');
        control = formGroup?.get(realKey) as AbstractControl;
      } else {
        control = formGroup?.get(attribute.key) as AbstractControl;
      }

      const childs: ControlInfo[] = [];
      const controlKey = attribute.key;

      const itemsControlInfo: { [key: string]: ControlInfo } = {};
      if (attribute.items?.properties) {
        // Array
        for (const key of Object.keys(attribute.items.properties)) {
          const itemInfo = attribute.items.properties[key];
          const fullKey = `${controlKey}.${key}`;
          const subItemsControlInfo: { [key: string]: ControlInfo } = {};

          if (itemInfo?.allOf) {
            // On balaye les objects du allOf
            for (const subItemInfo of itemInfo.allOf) {
              // On balaye les propriétés de l'object
              Object.keys(subItemInfo.properties).forEach((subKey) => {
                const subControl = new FormControl();
                subItemsControlInfo[subKey] = {
                  key: subKey,
                  fullKey: `${fullKey}.${subKey}`,
                  label: subKey,
                  placeholder: '',
                  description: subItemInfo.properties[subKey].description,
                  control: subControl,
                  childs: [],
                  type: this.getControlType(subItemInfo.properties[subKey]),
                  items: {},
                  maxItems: subItemInfo.properties[subKey].maxItems,
                  itemsControlInfo: {},
                  pattern: subItemInfo.properties[subKey].pattern,
                  minimum: subItemInfo.properties[subKey].minimum,
                  maximum: subItemInfo.properties[subKey].maximum,
                  maxLength: subItemInfo.properties[subKey].maxLength,
                  options: subItemInfo.properties[subKey].enum,
                  required: subItemInfo?.items?.required?.includes(subKey) || false,
                };
              });
            }
          }
          // On créé le control parent en ajoutant les controls enfants
          const subControl = new FormControl();
          itemsControlInfo[key] = {
            key: key,
            fullKey: fullKey,
            label: key,
            placeholder: '',
            description: itemInfo.description,
            control: subControl,
            childs: [],
            type: this.getControlType(itemInfo),
            items: itemInfo.items,
            maxItems: itemInfo.maxItems,
            itemsControlInfo: subItemsControlInfo,
            pattern: itemInfo.pattern,
            minimum: itemInfo.minimum,
            maximum: itemInfo.maximum,
            maxLength: itemInfo.maxLength,
            options: itemInfo.enum,
            required: attribute.items.required?.includes(key) || false,
          };
        }
      } else if (attribute.allOf) {
        // Objects
        for (const obj of attribute.allOf) {
          if (obj.properties) {
            for (const key of Object.keys(obj.properties)) {
              const itemInfo = obj.properties[key];
              const fullKey = `${controlKey}.${key}`;
              const subControl = new FormControl();
              itemsControlInfo[key] = {
                key: key,
                fullKey: fullKey,
                label: key,
                placeholder: '',
                description: itemInfo.description,
                control: subControl,
                childs: [],
                type: this.getControlType(itemInfo),
                items: itemInfo.items,
                maxItems: itemInfo.maxItems,
                itemsControlInfo: {},
                pattern: itemInfo.pattern,
                options: itemInfo.enum,
                required: obj?.required?.includes(key) || false,
              };
            }
          }
          // Hack for oneOf object that only contains one object
          if (obj.oneOf) {
            for (const key of Object.keys(obj.oneOf[0].properties)) {
              const itemInfo = obj.oneOf[0].properties[key];
              const fullKey = `${controlKey}.${key}`;
              const subControl = new FormControl();
              itemsControlInfo[key] = {
                key: key,
                fullKey: fullKey,
                label: key,
                placeholder: '',
                description: itemInfo.description,
                control: subControl,
                childs: [],
                type: this.getControlType(itemInfo),
                items: itemInfo.items,
                maxItems: itemInfo.maxItems,
                itemsControlInfo: {},
                pattern: itemInfo.pattern,
                options: itemInfo.enum,
                required: obj?.required?.includes(key) || false,
              };
            }
          }
        }
      } else if (attribute.items?.enum) {
        //S'il s'agit d'un array de string (enum)
        attribute.enum = attribute.items.enum;
      } else if (attribute.items?.type === 'string') {
        // Array of string not array of object
        const subControl = new FormControl();
        const itemInfo = attribute.items.properties[attribute.key];
        itemsControlInfo[attribute.key] = {
          key: '#',
          fullKey: '#',
          label: 'Texte',
          placeholder: '',
          description: '',
          control: subControl,
          childs: [],
          type: ControlType.text,
          items: {},
          maxItems: itemInfo.maxItems,
          itemsControlInfo: {},
          pattern: itemInfo.pattern,
          minimum: itemInfo.minimum,
          maximum: itemInfo.maximum,
          maxLength: itemInfo.maxLength,
          options: [],
          required: attribute?.items?.required?.includes(attribute.key) || false,
        };
      } else if (attribute.additionalProperties) {
        // FormGroup with keyvalue pair
        // We need one control key and one control value
        // We only process additionnalProperties with basic type (string, number, ...) not objects
        const subControlKey = new FormControl();
        itemsControlInfo['key'] = {
          key: 'key',
          fullKey: 'key',
          label: 'Clé',
          placeholder: '',
          description: '',
          control: subControlKey,
          childs: [],
          type: ControlType.text,
          items: {},
          maxItems: attribute.additionalProperties.maxItems,
          itemsControlInfo: {},
          pattern: '',
          options: [],
          required: attribute?.additionalProperties?.required?.includes('key') || false,
        };
        const subControlValue = new FormControl();
        itemsControlInfo['value'] = {
          key: 'value',
          fullKey: 'value',
          label: 'Valeur',
          placeholder: '',
          description: '',
          control: subControlValue,
          childs: [],
          type: this.getControlType(attribute.additionalProperties),
          items: {},
          maxItems: attribute.additionalProperties.maxItems,
          itemsControlInfo: {},
          pattern: '',
          options: [],
          required: false,
        };
      } else {
        const subControl = new FormControl(null, this.getValidators(attribute, attribute.required));
        itemsControlInfo[attribute.key] = {
          key: this.getKey(attribute.key),
          fullKey: attribute.fullKey,
          label: attribute.key,
          placeholder: '',
          description: attribute.description,
          control: subControl,
          childs: [],
          type: this.getControlType(attribute),
          items: {},
          maxItems: attribute.maxItems,
          itemsControlInfo: {},
          pattern: attribute.pattern,
          minimum: attribute.minimum,
          maximum: attribute.maximum,
          maxLength: attribute.maxLength,
          options: attribute.enum,
          required: attribute.required || false,
        };
      }
      mappedControls.push({
        key: attribute.key,
        fullKey: controlKey,
        label: attribute.key,
        placeholder: '',
        description: attribute.description,
        control: control as FormControl,
        childs: childs,
        type: this.getControlType(attribute),
        items: attribute.items,
        maxItems: attribute.maxItems,
        itemsControlInfo: itemsControlInfo,
        pattern: attribute.pattern,
        minimum: attribute.minimum,
        maximum: attribute.maximum,
        maxLength: attribute.maxLength,
        options: attribute.enum,
        required: attribute?.required || false,
      });
    });
    return mappedControls;
  }

  private getKey(fullKey: string): string {
    return fullKey.split('.').pop()!;
  }

  private getControlType(attribute: ResourceAttribute): ControlType {
    const isSelect: boolean = attribute.enum !== undefined && attribute.enum.length > 0;
    switch (attribute.type) {
      case 'string':
        if (isSelect) {
          return ControlType.select;
        } else {
          switch (attribute.format) {
            case 'date-time':
              return ControlType.datetime;
            case 'date':
              return ControlType.date;
            default:
              return ControlType.text;
          }
        }
      case 'integer':
      case 'number':
        return isSelect ? ControlType.select : ControlType.number;
      case 'boolean':
        return ControlType.checkbox;
      case 'date':
        return ControlType.date;
      case 'select':
        return ControlType.select;
      case 'array':
        return ControlType.array;
      case 'object':
        if (attribute.additionalProperties) {
          return ControlType.keyvalue;
        } else {
          return ControlType.object;
        }
      default:
        if (attribute.allOf && attribute.allOf.length === 1 && attribute.allOf[0].type === 'object') {
          return ControlType.object;
        } else {
          return ControlType.placeholder;
        }
    }
  }

  private isFormGroup(item: unknown): boolean {
    return item instanceof FormGroup;
  }

  private createFormControl(
    resource: string,
    schema: JsonSchema,
    isRequired: boolean,
    parent: string,
  ): AbstractControl {
    const validators = this.getValidators(schema, isRequired);

    if (schema.type === 'object') {
      return this.jsonSchemaToFormGroup(resource, schema, parent);
    } else if (schema.allOf) {
      return this.jsonSchemaToFormGroup(resource, schema, parent);
    } else if (schema.type === 'array') {
      return new FormArray([], validators);
    } else {
      return new FormControl('', validators);
    }
  }

  createControlInfoFormControl(controlInfo: ControlInfo): AbstractControl {
    const validators = this.getControlInfoValidators(controlInfo);
    return new FormControl('', validators);
  }

  private getControlInfoValidators(controlInfo: ControlInfo): ValidatorFn[] {
    const validators: ValidatorFn[] = [];

    if (controlInfo.required) {
      validators.push(Validators.required);
    }

    if (controlInfo.minLength !== undefined) {
      validators.push(Validators.minLength(controlInfo.minLength));
    }

    if (controlInfo.maxLength !== undefined) {
      validators.push(Validators.maxLength(controlInfo.maxLength));
    }

    if (controlInfo.minimum !== undefined) {
      validators.push(Validators.min(controlInfo.minimum));
    }

    if (controlInfo.maximum !== undefined) {
      validators.push(Validators.max(controlInfo.maximum));
    }

    if (controlInfo.pattern) {
      validators.push(Validators.pattern(controlInfo.pattern));
    }

    if (controlInfo.options) {
      validators.push((control) => {
        if ((control.value === null || control.value === '' || control.value === undefined) && !controlInfo.required) {
          return null;
        }
        return controlInfo.options!.includes(control.value) ? null : { enum: true };
      });
    }

    return validators;
  }

  private getValidators(schema: JsonSchema, isRequired: boolean): ValidatorFn[] {
    const validators: ValidatorFn[] = [];

    if (isRequired) {
      validators.push(Validators.required);
    }

    if (schema.minLength !== undefined) {
      validators.push(Validators.minLength(schema.minLength));
    }

    if (schema.maxLength !== undefined) {
      validators.push(Validators.maxLength(schema.maxLength));
    }

    if (schema.minimum !== undefined) {
      validators.push(Validators.min(schema.minimum));
    }

    if (schema.maximum !== undefined) {
      validators.push(Validators.max(schema.maximum));
    }

    if (schema.pattern) {
      validators.push(Validators.pattern(schema.pattern));
    }

    if (schema.enum) {
      validators.push((control) => {
        if ((control.value === null || control.value === '' || control.value === undefined) && !isRequired) {
          return null;
        }
        return schema.enum!.includes(control.value) ? null : { enum: true };
      });
    }

    if (schema.format === 'email') {
      validators.push(Validators.email);
    }

    return validators;
  }

  private async loadObjectsJsonFile(): Promise<any> {
    try {
      const response = await fetch(`assets/forms/objects/objects.json`);
      if (!response.ok) {
        throw new Error(`Failed to load JSON file: ${response.statusText}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error loading JSON file:', error);
      return null;
    }
  }
}
