import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-date-time-selector',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './date-time-selector.component.html',
  styleUrl: './date-time-selector.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeSelectorComponent),
      multi: true,
    },
  ],
})
export class DateTimeSelectorComponent implements ControlValueAccessor {
  dateTimeControl = new FormControl<string | null>(null);
  @Input() dateOnly = false;
  onChange: () => void = () => {};
  onTouched: () => void = () => {};

  transformDateToLocalFormat(value: string): string {
    if (!value) return '';
    // Convert UTC ISO string to local datetime-local format
    const date = new Date(value);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  transformDateToISOFormat(value: string): string {
    if (!value) return '';
    const date = new Date(value);

    // Convert to UTC
    const utcYear = date.getUTCFullYear();
    const utcMonth = String(date.getUTCMonth() + 1).padStart(2, '0');
    const utcDay = String(date.getUTCDate()).padStart(2, '0');
    const utcHours = String(date.getUTCHours()).padStart(2, '0');
    const utcMinutes = String(date.getUTCMinutes()).padStart(2, '0');
    const utcSeconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${utcYear}-${utcMonth}-${utcDay}T${utcHours}:${utcMinutes}:${utcSeconds}+00:00`;
  }

  writeValue(value: string | null): void {
    if (value === null || value === undefined) {
      this.dateTimeControl.setValue(null, { emitEvent: false });
      return;
    }

    const transformedValue = this.transformDateToLocalFormat(value);
    if (this.dateOnly) {
      this.dateTimeControl.setValue(transformedValue.split('T')[0], { emitEvent: false });
    } else {
      this.dateTimeControl.setValue(transformedValue, { emitEvent: false });
    }
  }

  registerOnChange(fn: (value: string | null) => void): void {
    this.dateTimeControl.valueChanges.subscribe((value) => {
      if (value === null || value === undefined) {
        fn(null);
        return;
      }
      const transformedValue = this.transformDateToISOFormat(value);
      if (this.dateOnly) {
        fn(transformedValue.split('T')[0]);
      } else {
        fn(transformedValue);
      }
    });
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.dateTimeControl.disable();
    } else {
      this.dateTimeControl.enable();
    }
  }
}
