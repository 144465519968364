<div class="d-flex flex-column gap-1">
  <a
    class="frosted-glass card-link p-1 inherit-color"
    *ngFor="let erp of erpList; let id = index"
    [routerLink]="'/erp/fiche-erp/' + erp.id"
  >
    <div class="d-flex gap-2 justify-content-between">
      <div class="d-flex align-items-center">
        <div class="icon d-flex align-items-center justify-content-center"><i class="erp"></i></div>
        <div class="d-flex flex-column gap-1">
          <app-badge-erp
            *ngIf="erp.id"
            [list]="[
              {
                id: erp.id,
                activite: erp.descriptif_technique?.analyse_risque?.activite_principale || undefined,
                name: erp.descriptif_technique?.libelle || 'ERP sans nom',
              },
            ]"
          ></app-badge-erp>
          <div class="d-flex align-items-center">
            <span class="fs-6">
              {{ erp.implantation.voie }}
              @if (erp.implantation.code_postal || erp.implantation.commune) {
                , {{ erp.implantation.code_postal }} {{ erp.implantation.commune }}
              }
            </span>
            <span *ngIf="erp?.implantation?.complement" class="bg-light address">{{
              erp.implantation.complement
            }}</span>
          </div>
        </div>
      </div>
      <div
        *ngIf="erp.descriptif_technique?.analyse_risque?.categorie || erp.references_exterieures?.length"
        class="d-flex gap-1 flex-wrap justify-content-end align-items-center"
      >
        <app-badge-categorie-erp
          [data]="erp.descriptif_technique?.analyse_risque?.categorie?.toString() || ''"
        ></app-badge-categorie-erp>
        <app-badge-external-reference
          *ngIf="erp.references_exterieures"
          [data]="erp.references_exterieures"
        ></app-badge-external-reference>
      </div>
    </div>
  </a>
</div>
