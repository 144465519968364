import { Component, OnInit } from '@angular/core';
import { Collection } from '@metarisc/metarisc-js';
import { MetariscService } from 'src/app/services/metarisc/metarisc.service';
import { ToastNotificationService } from 'src/app/services/toast-notification/toast-notification.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RssFeedService, rssItem } from 'src/app/services/rss-feed/rss-feed.service';
import { PaginationData } from '@metarisc/metarisc-js/lib/collection';
import { FeedMessage } from '@metarisc/metarisc-js/lib/model/FeedMessage';

import { NoResultComponent } from 'src/app/shared/components/no-result/no-result.component';
import { NewsFeedComponent } from 'src/app/components/news-feed/news-feed.component';
import { FeedMessageComponent } from 'src/app/components/feed-message/feed-message.component';
import { NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { NotificationComponent } from 'src/app/components/notification/notification.component';
import { Organisation } from '@metarisc/metarisc-js/lib/model/Organisation';
import { OrganisationChoiceService } from 'src/app/services/organisation-choice/organisation-choice.service';
import { BadgeOrganisationComponent } from 'src/app/shared/components/badges/badge-organisation/badge-organisation.component';
import { PaginateComponent } from 'src/app/shared/components/paginate/paginate.component';
import { NbToArrayPipe } from 'src/app/shared/pipes/nb-to-array.pipe';

@Component({
  templateUrl: './flux-page.component.html',
  styleUrls: ['./flux-page.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    TitleCasePipe,
    NewsFeedComponent,
    FeedMessageComponent,
    NotificationComponent,
    NoResultComponent,
    BadgeOrganisationComponent,
    PaginateComponent,
    NbToArrayPipe,
  ],
})
export class FluxPage implements OnInit {
  nbNotificationByPage = 10;

  waitingGetNotificationResponse = true;
  feedMessage: FeedMessage[] = [];
  groupedFeed: { [key: string]: FeedMessage[] } = {};
  pagination?: PaginationData;

  metariscClient = this.metariscService.getMetarisc();
  feedCollection?: Collection<FeedMessage>;

  rssFlux: Observable<rssItem[]> = this.rssFeed.getFeed(environment.rss);

  organisation?: Organisation;

  constructor(
    private metariscService: MetariscService,
    private toastNotificationService: ToastNotificationService,
    private rssFeed: RssFeedService,
    private organisationChoiceService: OrganisationChoiceService,
  ) {}

  ngOnInit(): void {
    const organisationId = this.organisationChoiceService.getOrganisationCookie();
    if (organisationId) {
      this.metariscService
        .getMetarisc()
        .organisations?.getOrganisation(organisationId)
        .then((organisation) => {
          this.organisation = organisation.data;
        });
    }

    this.feedCollection = this.metariscClient.feed?.paginateFeedMessages();
    this.loadPage(1, true);
  }

  loadPage(page: number, activeLoader = false): void {
    if (activeLoader) this.waitingGetNotificationResponse = true;
    // Appel API
    this.feedCollection
      ?.fetchPage(page, this.nbNotificationByPage)
      .then((response) => {
        // Mise à jour des données de notification
        this.feedMessage = response.data.data || [];
        this.groupedFeed = this.groupFeedMessageByDay();

        // Mise à jour des données de pagination
        this.pagination = response.data.meta?.pagination as PaginationData;
      })
      .catch((error) => {
        this.toastNotificationService.error(error.message);
      })
      .finally(() => {
        this.waitingGetNotificationResponse = false;
      });
  }

  groupFeedMessageByDay(): { [key: string]: FeedMessage[] } {
    // Grouper les notifications par jour
    const groupedByDay = this.feedMessage.reduce(
      (grouped, feedMessage) => {
        let date = "Aujourd'hui";
        if (feedMessage && feedMessage.date_de_creation)
          if (!this.equalsDate(new Date(feedMessage.date_de_creation), new Date())) {
            date = new Date(feedMessage.date_de_creation).toLocaleDateString('fr-FR', {
              day: 'numeric',
              month: 'long',
            });
          }
        if (!grouped[date]) {
          grouped[date] = [];
        }
        grouped[date].push(feedMessage);
        return grouped;
      },
      {} as { [key: string]: FeedMessage[] },
    );

    return groupedByDay;
  }

  getKeys(object: object): string[] {
    return Object.keys(object);
  }

  displayNoFeedMessage(): boolean {
    return this.feedMessage.length === 0 && !this.waitingGetNotificationResponse;
  }

  displayFeedList(): boolean {
    return this.feedMessage.length > 0 && !this.waitingGetNotificationResponse;
  }

  equalsDate(a: Date, b: Date): boolean {
    return a.getDay() === b.getDay() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();
  }
}
