<form [formGroup]="form">
  <div class="filters dropdown d-flex flex-wrap gap-3">
    @for (filtre of filtersToDisplay; track $index) {
      <ng-container *ngIf="filtre === 'trierPar'" [ngTemplateOutlet]="trierPar"></ng-container>
      <ng-container *ngIf="filtre === 'periode'" [ngTemplateOutlet]="periode"></ng-container>
      <ng-container *ngIf="filtre === 'geojson'" [ngTemplateOutlet]="geojson"></ng-container>
      <ng-container *ngIf="filtre === 'est_disponible'" [ngTemplateOutlet]="est_disponible"></ng-container>
      <ng-container *ngIf="filtre === 'domanialite'" [ngTemplateOutlet]="domanialite"></ng-container>
      <ng-container *ngIf="filtre === 'statut'" [ngTemplateOutlet]="statut"></ng-container>
      <ng-container *ngIf="filtre === 'type_pei'" [ngTemplateOutlet]="typePEI"></ng-container>
      <ng-container *ngIf="filtre === 'type_activite'" [ngTemplateOutlet]="typeERP"></ng-container>
      <ng-container *ngIf="filtre === 'type_dossier'" [ngTemplateOutlet]="typeDossier"></ng-container>
      <ng-container *ngIf="filtre === 'type_commission'" [ngTemplateOutlet]="typeCommission"></ng-container>
      <ng-container *ngIf="filtre === 'avis'" [ngTemplateOutlet]="avis"></ng-container>
      <ng-container *ngIf="filtre === 'locaux_sommeil'" [ngTemplateOutlet]="locauxSommeil"></ng-container>
      <ng-container *ngIf="filtre === 'workflow_actif'" [ngTemplateOutlet]="workflowActif"></ng-container>
      <ng-container *ngIf="filtre === 'affecte'" [ngTemplateOutlet]="affecte"></ng-container>
      <ng-container *ngIf="filtre === 'erp_lie'" [ngTemplateOutlet]="erpLie"></ng-container>
      <ng-container *ngIf="filtre === 'pei_lie'" [ngTemplateOutlet]="peiLie"></ng-container>
      <ng-container *ngIf="filtre === 'contenu'" [ngTemplateOutlet]="contenu"></ng-container>
      <ng-container *ngIf="filtre === 'a_visiter_en'" [ngTemplateOutlet]="aVisiterEn"></ng-container>
      <ng-container *ngIf="filtre === 'site_geographique'" [ngTemplateOutlet]="siteGeographique"></ng-container>
      <ng-container
        *ngIf="filtre === 'references_exterieures'"
        [ngTemplateOutlet]="references_exterieures"
      ></ng-container>
    }
  </div>
  <ng-template #trierPar>
    <app-generic-filter
      [title]="'Trier par'"
      [formControlMap]="getMap(['trierPar'])"
      (searchChange)="applyFilter($event)"
    >
      <div class="row align-items-center">
        <label class="col-md-4 col-form-label" for="trierPar">Trier par</label>
        <div class="col-md-8">
          <select formControlName="trierPar" class="col form-select form-select-sm" id="trierPar" name="trierPar">
            <option value="" disabled>-- Sélectionner --</option>
            <option value="date">Date</option>
            <option value="type">Type</option>
            <option value="nom">Nom</option>
            <option value="ville">Ville</option>
            <option value="disponibilte">Disponibilité</option>
            <option value="domanialite">Domanialité</option>
          </select>
        </div>
      </div>
    </app-generic-filter>
  </ng-template>
  <ng-template #periode>
    <app-generic-filter [title]="'Année'" [formControlMap]="getMap(['periode'])" (searchChange)="applyFilter($event)">
      <div class="mb-3">
        <label for="" class="form-label">Année</label>
        <select class="form-select" formControlName="periode" name="periode" id="periode">
          <option value="" disabled>--Selectionner --</option>
          <option value="2024-01-01/2025-01-01">2024</option>
          <option value="2023-01-01/2024-01-01">2023</option>
          <option value="2022-01-01/2023-01-01">2022</option>
          <option value="2021-01-01/2022-01-01">2021</option>
          <option value="2020-01-01/2021-01-01">2020</option>
        </select>
      </div>
    </app-generic-filter>
  </ng-template>
  <ng-template #geojson>
    <app-generic-filter
      [title]="'Zone de recherche'"
      [formControlMap]="getMap(['geojson'])"
      (searchChange)="applyFilter($event)"
    >
      <app-form-control-zone formControlName="geojson" (polygonMapConfirm)="polygonConfirm()"></app-form-control-zone>
    </app-generic-filter>
  </ng-template>

  <ng-template #est_disponible>
    <app-generic-filter
      [title]="'Disponibilité'"
      [formControlMap]="getMap(['est_disponible'])"
      (searchChange)="applyFilter($event)"
    >
      <div class="row align-item-center">
        <label class="col-md-4 col-form-label" for="est_disponible">Disponibilité</label>
        <div class="col-md-8">
          <select id="est_disponible" formControlName="est_disponible" class="col form-select form-select-sm">
            <option value="" disabled>-- Sélectionner --</option>
            <option [value]="true">Disponible</option>
            <option [value]="false">Indisponible</option>
          </select>
        </div>
      </div>
    </app-generic-filter>
  </ng-template>
  <ng-template #domanialite>
    <app-generic-filter
      [title]="'Domanialité'"
      [formControlMap]="getMap(['domanialite'])"
      (searchChange)="applyFilter($event)"
    >
      <div class="row align-item-center">
        <label class="col-md-4 col-form-label" for="domanialite">Domanialité</label>
        <div class="col-md-8">
          <select id="domanialite" formControlName="domanialite" class="col form-select form-select-sm">
            <option value="" disabled>-- Sélectionner --</option>
            <option value="publique">Publique</option>
            <option value="privee">Privée</option>
            <option value="privee_conventionnee">Privée conventionnée</option>
          </select>
        </div>
      </div>
    </app-generic-filter>
  </ng-template>
  <ng-template #typePEI>
    <app-generic-filter [title]="'Type'" [formControlMap]="getMap(['type'])" (searchChange)="applyFilter($event)">
      <div class="mb-3">
        <label for="" class="form-label">Type</label>
        <select class="form-select" formControlName="type" name="type" id="type">
          <option value="" disabled>--Selectionner --</option>
          <option value="PIBI">Pibi</option>
          <option value="PENA">Pena</option>
        </select>
      </div>
    </app-generic-filter>
  </ng-template>
  <ng-template #typeERP>
    <app-generic-filter
      [title]="'Type d\'activité'"
      [formControlMap]="getMap(['type_activite'])"
      (searchChange)="applyFilter($event)"
    >
      <div class="mb-3">
        <label for="" class="form-label">Type d'activité</label>
        <select class="form-select select-activity" formControlName="type_activite" name="type" id="type">
          <option value="" disabled>--Selectionner --</option>
          @for (activityType of activityTypes; track $index) {
            <option value="{{ activityType }}" title="{{ activityType }}">
              {{ activityType.length >= 76 ? activityType.slice(0, 76) + '...' : activityType }}
            </option>
          }
        </select>
      </div>
    </app-generic-filter>
  </ng-template>
  <ng-template #typeCommission>
    <app-generic-filter
      [title]="'Type de commission'"
      [formControlMap]="getMap(['type'])"
      (searchChange)="applyFilter($event)"
    >
      <div class="mb-3">
        <label for="" class="form-label">Type de commission</label>
        <select class="form-select" formControlName="type" name="type" id="type">
          <option value="" disabled>--Selectionner --</option>
          @for (commissionType of commissionTypes; track $index) {
            <option value="{{ commissionType }}">{{ commissionType }}</option>
          }
        </select>
      </div>
    </app-generic-filter>
  </ng-template>
  <ng-template #typeDossier>
    <app-generic-filter [title]="'Type'" [formControlMap]="getMap(['type'])" (searchChange)="applyFilter($event)">
      <div class="mb-3">
        <label for="" class="form-label">Type</label>
        <select class="form-select" formControlName="type" name="type" id="type">
          <option value="" disabled>--Selectionner --</option>
          @for (typeDossier of typesDossier; track $index) {
            <option value="{{ typeDossier }}">{{ typeDossier }}</option>
          }
        </select>
      </div>
    </app-generic-filter>
  </ng-template>

  <ng-template #workflowActif>
    <app-generic-filter
      [title]="'Workflow actif'"
      [formControlMap]="getMap(['workflow_actif'])"
      (searchChange)="applyFilter($event)"
    >
      <div class="mb-3">
        <label for="" class="form-label">Workflow actif</label>
        <select class="form-select" formControlName="workflow_actif" name="workflow_actif" id="workflow_actif">
          <option value="" disabled>--Selectionner --</option>
          @for (workflowActif of workflowsActif.keys(); track $index) {
            <option value="{{ workflowActif }}">{{ workflowsActif.get(workflowActif) }}</option>
          }
        </select>
      </div>
    </app-generic-filter>
  </ng-template>

  <ng-template #affecte>
    <app-generic-filter
      [title]="'Affectation'"
      [formControlMap]="getMap(['affecte'])"
      (searchChange)="applyFilter($event)"
      [enterKeyValidate]="false"
    >
      <div class="mb-3">
        <label for="affecte" class="form-label">Affectation</label>
        <app-search-in-filter
          formControlName="affecte"
          endpoint="/utilisateurs"
          search_method="last_name"
          [propertyForView]="['first_name', 'last_name']"
        ></app-search-in-filter>
      </div>
    </app-generic-filter>
  </ng-template>

  <ng-template #erpLie>
    <app-generic-filter
      [title]="'ERP lié'"
      [formControlMap]="getMap(['erp'])"
      [enterKeyValidate]="false"
      (searchChange)="applyFilter($event)"
    >
      <div class="mb-3">
        <label for="erp_lie" class="form-label">ERP lié</label>
        <app-search-in-filter
          formControlName="erp"
          search_method="libelle"
          endpoint="erp"
          [propertyForView]="['descriptif_technique.libelle']"
        ></app-search-in-filter>
      </div>
    </app-generic-filter>
  </ng-template>

  <ng-template #contenu>
    <app-generic-filter [title]="'Contenu'" [formControlMap]="getMap(['contenu'])" (searchChange)="applyFilter($event)">
      <div class="mb-3">
        <label for="contenu" class="form-label">Contenu</label>
        <input formControlName="contenu" class="form-control" id="contenu" type="text" />
      </div>
    </app-generic-filter>
  </ng-template>

  <ng-template #peiLie>
    <app-generic-filter [title]="'PEI lié'" [formControlMap]="getMap(['pei'])" (searchChange)="applyFilter($event)">
      <div class="mb-3">
        <label for="pei_lie" class="form-label">PEI lié</label>
        <app-search-in-filter
          formControlName="pei"
          search_method="numero"
          endpoint="pei"
          [propertyForView]="['numero']"
        ></app-search-in-filter>
      </div>
    </app-generic-filter>
  </ng-template>

  <ng-template #statut>
    <app-generic-filter [title]="'statut'" [formControlMap]="getMap(['statut'])" (searchChange)="applyFilter($event)">
      <div class="mb-3">
        <label for="" class="form-label">Statut</label>
        <select class="form-select" formControlName="statut" name="statut" id="statut">
          <option value="" disabled>--Selectionner --</option>
          <option value="reprise_de_donnees">Reprise de données</option>
          <option value="declaration_en_cours">Déclaration en cours</option>
          <option value="reconnaissance_operationnelle_initiale">Reconnaissance opérationnelle initiale</option>
          <option value="actif">Actif</option>
          <option value="projet">Projet</option>
          <option value="reforme">Réformé</option>
        </select>
      </div>
    </app-generic-filter>
  </ng-template>
  <ng-template #avis>
    <app-generic-filter
      [title]="'Avis'"
      [formControlMap]="getMap(['avis_exploitation'])"
      (searchChange)="applyFilter($event)"
    >
      <div class="row align-items-center">
        <label class="col-md-4 col-form-label" for="trierPar">Avis</label>
        <div class="col-md-8">
          <select
            formControlName="avis_exploitation"
            class="col form-select form-select-sm"
            id="trierPar"
            name="trierPar"
          >
            <option value="" disabled>-- Sélectionner --</option>
            <option value="favorable">Favorable</option>
            <option value="defavorable">Défavorable</option>
          </select>
        </div>
      </div>
    </app-generic-filter>
  </ng-template>
  <ng-template #locauxSommeil>
    <app-generic-filter
      [title]="'Locaux sommeil'"
      [formControlMap]="getMap(['presence_locaux_sommeil'])"
      (searchChange)="applyFilter($event)"
    >
      <div class="row align-items-center">
        <div class="form-check form-switch">
          <input
            formControlName="presence_locaux_sommeil"
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="locauxsommeil"
          />
          <label class="form-label" for="locauxsommeil">Présence de locaux sommeil</label>
        </div>
      </div>
    </app-generic-filter>
  </ng-template>
  <ng-template #references_exterieures>
    <app-generic-filter
      [title]="'Références externes'"
      [formControlMap]="getMap(['references_exterieures'])"
      (searchChange)="applyFilter($event)"
    >
      <div class="row align-items-center">
        <label class="form-label" for="references_exterieures">Références externes</label>
        <div>
          <input
            formControlName="references_exterieures"
            class="form-control"
            type="text"
            role="textbox"
            id="references_exterieures"
          />
        </div>
      </div>
    </app-generic-filter>
  </ng-template>

  <ng-template #aVisiterEn>
    <app-generic-filter
      [title]="'A visiter en'"
      [formControlMap]="getMap(['a_visiter_en'])"
      (searchChange)="applyFilter($event)"
    >
      <div class="row align-items-center">
        <label class="form-label" for="a_visiter_en">A visiter en</label>
        <div>
          <select class="form-select" formControlName="a_visiter_en" name="a_visiter_en" id="a_visiter_en">
            <option value="" disabled>-- Sélectionner --</option>
            @for (year of years; track $index) {
              <option [value]="year">{{ year }}</option>
            }
          </select>
        </div>
      </div>
    </app-generic-filter>
  </ng-template>

  <ng-template #siteGeographique>
    <app-generic-filter
      [title]="'Site géographique'"
      [formControlMap]="getMap(['site_geographique'])"
      (searchChange)="applyFilter($event)"
    >
      <div class="mb-3">
        <label for="site_geographique" class="form-label">Site géographique</label>
        <div>
          <input
            formControlName="site_geographique"
            class="form-control"
            type="text"
            role="textbox"
            id="site_geographique"
            (input)="searchSiteGeographique($event)"
          />
        </div>
        @if (siteGeographiqueResults && siteGeographiqueResults.length > 0) {
          <div>
            @for (result of siteGeographiqueResults; track $index) {
              <div
                class="site-geographique-result d-flex align-items-center gap-1 mt-1"
                (click)="selectSiteGeographique(result)"
              >
                <div class="bi bi-geo-alt-fill text-primary"></div>
                <div class="site-geographique-result-label">{{ result.libelle }}</div>
              </div>
            }
          </div>
        }
      </div>
    </app-generic-filter>
  </ng-template>
</form>
