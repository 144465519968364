import { Component } from '@angular/core';
import { CalendarOptions, EventClickArg } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEventModal } from 'src/app/shared/modals/calendar-event-modal/calendar-event-modal.component';
import { Evenement, TypeEnum } from '@metarisc/metarisc-js/src/model/Evenement';
import { MetariscService } from 'src/app/services/metarisc/metarisc.service';
import { FullCalendarModule } from '@fullcalendar/angular';

type eventCalendar = {
  start?: Date;
  end?: Date;
  title?: string;
  id?: string;
  description: string | null;
  type?: TypeEnum;
};

type viewInfo = {
  end: Date;
  endStr: string;
  start: Date;
  startStr: string;
  timeZone: string;
};

@Component({
  templateUrl: './calendar-page.component.html',
  styleUrls: ['./calendar-page.component.scss'],
  standalone: true,
  imports: [FullCalendarModule],
})
export class CalendarPage {
  constructor(
    private metariscService: MetariscService,
    private modalService: NgbModal,
  ) {}

  calendarOptions: CalendarOptions = {
    locale: 'fr',
    firstDay: 1,
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin, timeGridPlugin],
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay',
    },
    titleFormat: { day: 'numeric', month: 'long', year: 'numeric' },
    buttonText: { month: 'Mois', week: 'Semaine', day: 'Jour', today: "Aujourd'hui" },
    allDaySlot: false,
    slotMinTime: '06:00',
    slotMaxTime: '22:00',
    weekends: true,
    height: '100%',
    events: this.loadEvents.bind(this),
    eventClick: this.handleDateClick.bind(this),
    eventClassNames: 'cursor-pointer',
  };
  colorByType: { [key: string]: { bg?: string; text?: string } } = {
    DEFAUT: { bg: 'rgb(78, 185, 168)', text: 'white' },
    RECONNAISSANCE_OPERATIONNELLE_ANNUELLE: { bg: 'rgb(23, 162, 184)', text: 'white' },
    VISITE_PERIODIQUE: { bg: 'rgb(40, 167, 69)', text: 'white' },
    COMMISSION_SECURITE: { bg: 'rgb(220, 53, 69)', text: 'white' },
  };

  private async getEvents(startStr: string, endStr: string): Promise<Evenement[] | undefined> {
    const collection = this.metariscService
      .getMetarisc()
      .evenements?.paginateEvenements(`${encodeURIComponent(startStr)}/${encodeURIComponent(endStr)}`, undefined);
    return await collection?.autoPagingIteratorToArray();
  }

  private async loadEvents(
    info: viewInfo,
    successCallback: (data: eventCalendar[]) => void,
    failureCallback: (error: Error) => void,
  ): Promise<void> {
    const { startStr, endStr } = info;

    const data = await this.getEvents(startStr.split('T')[0], endStr.split('T')[0]);

    if (!data) return failureCallback(new Error('Impossible de charger les évènements'));

    const parseData = data.map((event: Evenement) => {
      return {
        start: event.date_debut,
        end: event.date_fin,
        title: event.title,
        id: event.id,
        description: event.description,
        type: event.type,
        backgroundColor: this.colorByType[event.type ? event.type : ''].bg || '',
        textColor: this.colorByType[event.type ? event.type : ''].text || '',
      };
    });

    successCallback(parseData);
  }

  handleDateClick(arg: EventClickArg): void {
    const modalRef = this.modalService.open(CalendarEventModal, {
      modalDialogClass: 'frosted-glass',
    });

    console.log(arg);

    modalRef.componentInstance.calendarEvent = arg.event;
  }
}
