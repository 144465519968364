import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReferenceExterieure } from '@metarisc/metarisc-js/lib/model/ReferenceExterieure';

@Component({
  selector: 'app-badge-external-reference',
  templateUrl: './badge-external-reference.component.html',
  styleUrls: ['./badge-external-reference.component.scss'],
  standalone: true,
  imports: [NgFor],
})
export class BadgeExternalReferenceComponent {
  @Input() data!: ReferenceExterieure[];
}
