import {
  Component,
  EventEmitter,
  Output,
  input,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild,
  OnInit,
  Input,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Collection } from '@metarisc/metarisc-js';
import { debounceTime, fromEvent } from 'rxjs';
import { MetariscService } from 'src/app/services/metarisc/metarisc.service';

@Component({
  selector: 'app-generic-search-input',
  templateUrl: './generic-search-input.component.html',
  styleUrls: ['./generic-search-input.component.scss'],
  standalone: true,
  imports: [FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericSearchInputComponent implements OnInit {
  @Input() search_value?: string;

  endpoint = input.required<string>();
  query_params = input<{ [param: string]: string | string[] }>({});
  search_method = input.required<string>();

  @Output() pressEnterAction = new EventEmitter<Collection<any>>();
  @Output() pressKeyAction = new EventEmitter<Collection<any>>();

  @Input() loadInit = true;

  @ViewChild('searchInput', { static: true }) searchInput!: ElementRef;

  constructor(private metariscService: MetariscService) {}

  ngOnInit(): void {
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.searchOnKeyPress();
      });
    if (this.loadInit) {
      this.searchOnEnterPress();
      this.searchOnKeyPress();
    }
  }

  prepareSearch(): Collection<unknown> {
    const params = { ...this.query_params() };
    if (this.search_value) {
      params[this.search_method()] = this.search_value;
    }
    return this.metariscService.getMetarisc().collect({
      method: 'GET',
      endpoint: this.endpoint(),
      params: params,
    });
  }

  searchOnKeyPress(): void {
    this.pressKeyAction.emit(this.prepareSearch());
  }

  searchOnEnterPress(): void {
    this.pressEnterAction.emit(this.prepareSearch());
  }

  resetSearch(): void {
    this.search_value = '';
    this.searchOnKeyPress();
    this.searchOnEnterPress();
  }
}
