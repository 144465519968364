import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utilisateur } from '@metarisc/metarisc-js/lib/model/Utilisateur';
import { Organisation } from '@metarisc/metarisc-js/src/model/Organisation';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MetariscService } from 'src/app/services/metarisc/metarisc.service';
import { OrganisationChoiceService } from 'src/app/services/organisation-choice/organisation-choice.service';
import { LoadingComponent } from 'src/app/shared/components/loading/loading.component';

@Component({
  selector: 'app-organization-choice-page',
  templateUrl: './organization-choice-page.component.html',
  styleUrls: ['./organization-choice-page.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, LoadingComponent],
})
export class OrganizationChoicePage implements OnInit {
  selected_id?: string;
  organizations?: Organisation[];
  loading!: Promise<void>;

  constructor(
    private authService: AuthService,
    private meta: MetariscService,
    private orgaChoice: OrganisationChoiceService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.loading = this.authService
      .getConnectedUser()
      .then((user: Utilisateur) => {
        if (user.id) {
          return this.meta
            .getMetarisc()
            .utilisateurs?.paginateUtilisateurOrganisations(user.id)
            .autoPagingIteratorToArray();
        } else {
          throw new Error("Identifiant de l'utilisateur connecté introuvable");
        }
      })
      .then((response) => {
        if (response) {
          this.organizations = response.map((org) => org.organisation).filter((org): org is Organisation => !!org);

          if (this.organizations.length === 1) {
            this.selected_id = this.organizations[0].id;
          }
          if (this.organizations.length === 0) {
            throw new Error(
              "Vous ne faites partie d'aucune organisation, merci de contacter votre administrateur. Si vous pensez qu'il s'agit d'une erreur, merci de contacter le support technique.",
            );
          }
        }
      });
  }

  select(): void {
    if (this.selected_id) {
      this.orgaChoice.setOrganisationChoice(this.selected_id);
      this.meta.getMetarisc().setActiveOrganisation(this.selected_id);

      this.router.navigateByUrl('/');
    }
  }
}
