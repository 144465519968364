<div class="d-flex justify-content-start gap-2">
  @for (tab of tabs; track $index) {
    @if (tab.spacer) {
      <div class="spacer"></div>
    } @else {
      @if (!tab.hidden) {
        <div class="nav-item d-flex align-items-center gap-1">
          <a
            class="nav-link"
            [ngClass]="{ disabled: tab.disabled }"
            [routerLink]="tab.url"
            routerLinkActive="active ff-medium"
          >
            {{ tab.label }}
          </a>
          @if (tab.count) {
            <span class="count">{{ tab.count }}</span>
          }
        </div>
      }
    }
  }
</div>
