import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuth2 } from '@metarisc/metarisc-js';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './access-page.component.html',
  styleUrls: ['./access-page.component.scss'],
  standalone: true,
})
export class AccessPage implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const code = this.route.snapshot.queryParamMap.get('code');
    if (!code) {
      this.login();
    } else {
      this.authService.retrieveToken(code).finally(() => {
        this.router.navigateByUrl(sessionStorage.getItem('lastUrl') || '');
      });
    }
  }

  login(): void {
    window.location.href = OAuth2.authorizeUrl({
      response_type: environment.paramAuth.responseType,
      scope: environment.paramAuth.scope,
      client_id: environment.paramAuth.clientId,
      redirect_uri: environment.paramAuth.redirectUri,
    });
  }
}
