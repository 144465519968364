import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

export type Tab = {
  label: string;
  url: string;
  disabled?: boolean;
  hidden?: boolean;
  spacer?: boolean;
  count?: number;
};

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  standalone: true,
  imports: [NgClass, RouterLink, RouterLinkActive],
})
export class NavMenuComponent {
  @Input() tabs: Tab[] = [];
}
