import { computed, inject, Injectable, signal } from '@angular/core';
import { Dossier, TypeEnum } from '@metarisc/metarisc-js/lib/model/Dossier';
import { ERP } from '@metarisc/metarisc-js/lib/model/ERP';
import { PEI } from '@metarisc/metarisc-js/lib/model/PEI';
import { MetariscService } from 'src/app/services/metarisc/metarisc.service';

@Injectable({
  providedIn: 'root',
})
export class FicheDossierService {
  mapType = new Map<TypeEnum, string>([
    [TypeEnum.ERP_AUTORISATION_DE_TRAVAUX, 'AT'],
    [TypeEnum.ERP_PERMIS_DE_CONSTRUIRE, 'PC'],
    [TypeEnum.ERP_LEVEE_DE_PRESCRIPTIONS, 'Levée presc'],
    [TypeEnum.ERP_CHANGEMENT_DE_DUS, 'DUS'],
    [TypeEnum.ERP_SALON_TYPE_T, 'Salon T'],
    [TypeEnum.ERP_UTILISATION_EXCEPTIONNELLE_DE_LOCAUX, 'GN6'],
    [TypeEnum.ERP_DEMANDE_D_IMPLANTATION_CTS_INFERIEUR_6_MOIS, 'CTS<6M'],
    [TypeEnum.ERP_DEMANDE_D_IMPLANTATION_CTS_SUPERIEUR_6_MOIS, 'CTS>6M'],
    [TypeEnum.ERP_DEROGATION, 'Déro'],
    [TypeEnum.ERP_ETUDE_CAHIER_DES_CHARGES_TYPE_T, 'SSI'],
    [TypeEnum.ERP_LEVEE_DE_RESERVE, 'Levée réserv'],
    [TypeEnum.ERP_ECHEANCIER_DE_TRAVAUX, 'Echéancier'],
    [TypeEnum.ERP_CAHIER_DES_CHARGES_SSI, 'SSI'],
    [TypeEnum.ERP_ETUDE_SUITE_A_UN_AVIS_DIFFERE, 'Avis diff'],
    [TypeEnum.ERP_VISITE_PERIODIQUE, 'VP'],
    [TypeEnum.ERP_VISITE_AVANT_OUVERTURE, 'VAO'],
    [TypeEnum.ERP_VISITE_CONTROLE, 'VC'],
    [TypeEnum.ERP_VISITE_INOPINEE, 'VI'],
    [TypeEnum.ERP_VISITE_CHANTIER, 'Vchantier'],
    [TypeEnum.DECI_DECLARATION_PEI, 'Décl. PEI'],
  ]);
  dossier = signal<DossierType | undefined>(undefined);
  typeDim = computed(() => {
    const dossier = this.dossier();
    return dossier ? this.mapType.get(dossier.type) || dossier.type : undefined;
  });
  private metaService = inject(MetariscService);

  sectionElement?: HTMLElement;

  setDossier(dossier: Dossier): void {
    this.dossier.set(dossier);
  }
  setDossierFromApi(id: string): Promise<unknown> | undefined {
    return this.metaService
      .getMetarisc()
      .dossiers?.getDossier(id)
      .then((response) => {
        this.setDossier(response.data as Dossier);
        return response;
      });
  }
}
export type DossierType = Dossier & { erp?: ERP; pei?: PEI };
