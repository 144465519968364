import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, OnInit, Input, Output, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Collection } from '@metarisc/metarisc-js';
import { RechercheService, Univers } from 'src/app/features/recherche/services/recherche-service/recherche.service';
import { MetariscService } from 'src/app/services/metarisc/metarisc.service';
import { FiltersControlValueAccessor } from '../../forms/filters/filters.component';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportProgressModal, Field } from '../../modals/export-progress-modal/export-progress-modal.component';

@Component({
  selector: 'app-general-search',
  templateUrl: './general-search.component.html',
  styleUrls: ['./general-search.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgClass, NgIf, NgFor, FiltersControlValueAccessor],
})
export class GeneralSearchComponent implements OnInit, OnDestroy {
  @Input() univers_list: Univers[] = [];
  @Input() show_univers = true;
  @Input() default_univers?: string;
  @Input() show_search_input = true;

  @Output() changeUnivers = new EventEmitter<Univers>();
  @Output() searchAction = new EventEmitter<Collection<any>>();

  selected_univers?: Univers;
  univers_placeholder = 'Rechercher';
  univers_exported_keys: Field[] = [];
  search_input = '';
  filters_form: FormGroup = this.fb.group({
    filters: [],
  });

  private searchText = new Subject<string>();

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private rechercheService: RechercheService,
    private metariscService: MetariscService,
    private modalService: NgbModal,
  ) {}

  get params(): { [param: string]: string | string[] } {
    const params = this.filters_form.controls['filters'].value;
    if (this.selected_univers?.search_method && this.search_input) {
      params[this.selected_univers.search_method] = this.search_input;
    }
    return params;
  }

  @ViewChild('inputSearchElement') inputSearchElement!: ElementRef;

  ngOnInit(): void {
    const query_params = this.route.snapshot.queryParams;
    // Univers
    if (this.univers_list.length) {
      let univers;
      if (this.default_univers) {
        const find_univers = this.univers_list.find((u) => u.endpoint === this.default_univers);
        if (find_univers) {
          univers = find_univers;
        }
      }
      this.selected_univers = univers || this.univers_list[0];
      this.onChangeUnivers(false);
    }
    // Input de recherche
    if (this.selected_univers) {
      this.search_input = query_params[this.selected_univers.search_method] || '';
    }

    // Initialisation de l'événement sur le changement d'un filtre
    this.filters_form.controls['filters'].valueChanges.subscribe(() => {
      this.search();
      this.rechercheService.updateQueryParams(this.params);
    });

    this.rechercheService.reloadSearch.subscribe((text: string) => {
      this.search_input = text;
      this.inputSearchElement.nativeElement.focus();
      this.search();
    });
    //Handle debouncing of search
    this.searchText.pipe(debounceTime(300), distinctUntilChanged()).subscribe(() => {
      this.search();
      if (this.selected_univers?.search_method) this.params[this.selected_univers.search_method] = this.search_input;
      this.filterParams(this.params);
      this.rechercheService.updateQueryParams(this.params);
    });

    // Init Parameters once everything is initialized
    this.filters_form.controls['filters'].patchValue({ ...query_params });
    this.filters_form.updateValueAndValidity();
  }
  filterParams(params: { [param: string]: string | string[] }): void {
    Object.keys(params).forEach((key) => params[key] === '' && delete params[key]);
  }

  debounceSearch(text: string): void {
    this.searchText.next(text);
  }

  resetSearch(): void {
    this.searchText.next('');
    this.search_input = '';
    this.search();
  }

  getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  onChangeUnivers(emit = true): void {
    if (this.selected_univers) {
      // Mise à jour du placeholer
      this.refreshUniversPlaceholderAndExportKeys();
      // Réinitialisation des filtres
      this.filters_form.controls['filters'].reset();
      this.filters_form.updateValueAndValidity();
      // Événement pour le parent
      if (emit) this.changeUnivers.emit(this.selected_univers);
      // Update de la recherche
      this.search();
    }
  }

  refreshUniversPlaceholderAndExportKeys(): void {
    if (this.selected_univers) {
      switch (this.selected_univers.libelle) {
        case 'PEI':
          this.univers_placeholder = 'Rechercher par numéro';
          this.univers_exported_keys = [{ field: 'id', title: 'ID' }];
          break;
        case 'ERP':
          this.univers_placeholder = 'Rechercher par nom';
          this.univers_exported_keys = [
            { field: 'descriptif_technique.libelle', title: 'Libellé' },
            { field: 'implantation.voie', title: 'Adresse' },
            { field: 'implantation.commune', title: 'Commune' },
            { field: 'implantation.code_insee', title: 'Code INSEE' },
            { field: 'descriptif_technique.analyse_risque.categorie', title: 'Catégorie' },
            { field: 'descriptif_technique.analyse_risque.activite_principale', title: 'Activité principale' },
            { field: 'descriptif_technique.presence_locaux_sommeil', title: 'Présence de locaux à sommeil' },
          ];
          break;
        case 'Dossier':
          this.univers_placeholder = 'Rechercher par nom';
          this.univers_exported_keys = [
            { field: 'objet', title: 'Objet' },
            { field: 'erp.descriptif_technique.libelle', title: 'Libellé ERP' },
            { field: 'erp.commission_de_securite.libelle', title: 'Commission de sécurité' },
            { field: 'type', title: 'Type' },
          ];
          break;
        default:
          this.univers_placeholder = 'Rechercher';
          this.univers_exported_keys = [];
          break;
      }
    }
  }

  getFileName(params: { [param: string]: string | string[] }): string {
    const paramsStr = Object.entries(params)
      .filter(([key, value]) => value !== 'null' && value !== null && key !== 'geojson')
      .map(([key, value]) => `${key}=${value}`)
      .join('_');
    let fileName = `export_${this.selected_univers?.libelle}_${paramsStr}.csv`;
    fileName = fileName.replace(/[<>:"/\\|?*\s]/g, '_');
    return fileName;
  }

  async exportSearch(): Promise<void> {
    const hardLimit = 2500;
    if (this.selected_univers) {
      const params = { ...this.params };
      if (this.search_input) {
        params[this.selected_univers.search_method] = this.search_input;
      } else {
        delete params[this.selected_univers.search_method];
      }
      console.log('params', params);
      console.log('search_input', this.search_input);
      console.log('selected_univers', this.selected_univers);
      const collection = this.metariscService.getMetarisc().collect({
        method: 'GET',
        endpoint: this.selected_univers.endpoint,
        params: params,
      });

      // Ouvrir la modale de progression
      const modalRef = this.modalService.open(ExportProgressModal, {
        backdrop: 'static',
        keyboard: false,
      });

      modalRef.componentInstance.fileName = this.getFileName(params);
      let currentIndex = 0;
      const itemsPerPage = 100;
      let totalItems = 0;
      let totalPages = 0;
      const data: any[] = [];

      const pagination = (await collection.fetchPage(1, 1)).data.meta?.pagination;
      totalItems = pagination?.total as number;
      totalPages = Math.ceil(totalItems / itemsPerPage);
      modalRef.componentInstance.totalItems = totalItems;
      modalRef.componentInstance.itemsPerPage = itemsPerPage;
      modalRef.componentInstance.totalPages = totalPages;
      if (totalItems > hardLimit) {
        modalRef.componentInstance.totalItems = hardLimit;
        modalRef.componentInstance.totalPages = Math.ceil(hardLimit / itemsPerPage);
        modalRef.componentInstance.isLimited = true;
        modalRef.componentInstance.hardLimit = hardLimit;
      }

      for await (const erp of collection.autoPagingIterator()) {
        if (!modalRef.componentInstance) {
          // Si la modale est fermée, on arrête la boucle
          break;
        }
        currentIndex++;
        data.push(erp);
        modalRef.componentInstance.currentIndex = currentIndex;
        if (currentIndex >= hardLimit) {
          break;
        }
      }
      if (modalRef.componentInstance) {
        modalRef.componentInstance.data = data;
        modalRef.componentInstance.generateCsv(this.univers_exported_keys);
      }
    }
  }

  search(): void {
    if (this.selected_univers) {
      const params = { ...this.params };
      if (this.search_input) {
        params[this.selected_univers.search_method] = this.search_input;
      } else {
        delete params[this.selected_univers.search_method];
      }
      const collection = this.metariscService.getMetarisc().collect({
        method: 'GET',
        endpoint: this.selected_univers.endpoint,
        params: params,
      });
      this.searchAction.emit(collection);
    }
  }

  isSelectedUniverse(libelle: string): boolean {
    return !!this.selected_univers && this.selected_univers.libelle === libelle;
  }

  ngOnDestroy(): void {
    this.searchText.complete();
  }
}
