import { Component } from '@angular/core';

export type DashboardItem = {
  title: string;
  command: string;
};
@Component({
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
  standalone: true,
})
export class DashboardPage {
  dashboardList: DashboardItem[] = [
    {
      title: 'Courriers sans réponse',
      command: '/courriers-sans-reponse',
    },
    {
      title: 'Dossier avec absence de quorum',
      command: '/dossier-avec-absence-de-quorum',
    },
    {
      title: 'Dossiers avec avis différés',
      command: '/dossiers-avec-avis-differes',
    },
    {
      title: 'Dossiers avec des pieces jointes non envoyées',
      command: '/dossiers-avec-des-pieces-jointes-non-envoyees',
    },
    {
      title: 'Dossiers avec statut ne peut se prononcer',
      command: '/dossiers-avec-statut-ne-peut-se-prononcer',
    },
    {
      title: 'Dossiers avec une date de levée des prescriptions',
      command: '/dossiers-avec-une-date-de-levée-des-prescriptions',
    },
    {
      title: 'Dossiers commissions échus sans avis',
      command: '/dossiers-commissions-echus-sans-avis',
    },
    {
      title: 'Dossiers commissions échus sans avis',
      command: '/dossiers-commissions-echus-sans-avis',
    },
    {
      title: 'Dossiers platau à traiter',
      command: '/dossiers-platau-a-traiter',
    },
    {
      title: 'Dossiers platau avec de nouvelles pieces jointes',
      command: '/dosier-platau-avec-de-nouvelles-pieces-jointes',
    },
    {
      title: 'Dossiers suivis déverrouillés',
      command: '/dossiers-suivis-deverrouilles',
    },
    {
      title: 'Dossiers suivis sans avis',
      command: '/dossiers-suivis-sans-avis',
    },
    {
      title: 'Etablissements défavorables suivis',
      command: '/etablissements-defavorables-suivis',
    },
    {
      title: 'Etablissements défavorables sur commune utilisateur',
      command: '/etablissements-defavorables-sur-commune-utilisateur',
    },
    {
      title: 'Etablissements sans préventionniste',
      command: '/etablissements-sans-preventionniste',
    },
    {
      title: 'Etablissements sans prochaine visite périodique',
      command: '/etablissements-sans-prochaine-visite-periodique',
    },
    {
      title: 'Etablissements sans prochaine visite périodique',
      command: '/etablissements-sans-prochaine-visite-periodique',
    },
    {
      title: 'Etablissements sous avis défavorable',
      command: '/etablissements-sous-avis-defavorable',
    },
    {
      title: 'Etablissements suivis',
      command: '/etablissements-suivis',
    },
    {
      title: 'Ordre du jour des prochaines commissions (combien de jours ?)',
      command: '/ordre-du-jour-des-prochaines-commissions',
    },
    {
      title: 'Prochaines commissions (combien de jours ?)',
      command: '/prochaines-commissions',
    },
  ];

  onChange(event: Event): void {
    const selectedValue = (event.target as HTMLSelectElement).value;
    if (selectedValue) {
      console.log('Dashboard', selectedValue);
    }
  }
}
