import { Injectable, signal } from '@angular/core';
import { Notification } from '@metarisc/metarisc-js/src/model/Notification';
import { MetariscService } from '../metarisc/metarisc.service';
import { ToastNotificationService } from '../toast-notification/toast-notification.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationListenerService {
  notifications?: Notification[];
  unread_notification_counter = signal(0);

  constructor(
    private metariscService: MetariscService,
    private toastNotificationService: ToastNotificationService,
  ) {}

  start(): void {
    setInterval(() => {
      this.metariscService
        .getMetarisc()
        .notifications?.paginateNotifications()
        .autoPagingIteratorToArray(10)
        .then((results: Notification[]) => {
          if (this.notifications) {
            let i = 0;
            while (i < results.length && results[i].id !== this.notifications[0].id) {
              this.toastNotificationService.info('Nouvelle notification : ' + results[i].title);
              this.unread_notification_counter.update((value) => ++value);
              i++;
            }
          }
          this.notifications = results;
        });
    }, 10 * 1000);
  }

  resetUnreadCounter(): void {
    this.unread_notification_counter.set(0);
  }
}
