<div class="frosted-glass h-100 d-flex flex-column overflow-hidden p-1">
  <div class="text-secondary d-flex fs-6 ff-regular lh-1 mb-1_5 justify-content-end info-notification">
    <i class="bi bi-info-circle me-1"></i
    ><span
      ><span class="text-decoration-underline">Sirène</span> utilise l'IA. Les réponses peuvent comporter des
      erreurs.</span
    >
  </div>
  <div class="parent">
    <div class="messages d-flex flex-column-reverse">
      @if (messages.length === 0) {
        <app-no-result title="Aucun message pour le moment"></app-no-result>
      }
      @for (message of messages | reverse; track message.id) {
        <div class="d-flex position-relative" [ngClass]="{ 'message-me': message.me, 'mt-2': true }">
          <img
            [src]="
              message.me ? utilisateur?.avatar_url || 'assets/images/avatar_large.png' : 'assets/images/metadome.svg'
            "
            class="user-icon position-absolute"
          />
          <div class="frosted-glass p-1_5 bloc-message">
            <div *ngIf="!message.me" class="d-flex mb-1 gap-2" [ngClass]="{ 'justify-content-end': message.me }">
              <span *ngIf="!message.me" class="ff-medium">Sirène</span>
            </div>
            <div
              #progressiveTextBloc
              class="message-text mb-0_5"
              [ngClass]="{ 'text-end': message.me }"
              [innerHTML]="message.content"
            ></div>
            <div class="text-end">
              <span class="small text-secondary">{{ message.date_de_creation | date: 'le dd/MM/yyyy à HH:mm' }}</span>
            </div>
          </div>
        </div>
      }
    </div>
    <app-loading [load]="loading" size="large"></app-loading>
  </div>
  <div class="block-form frosted-glass">
    <form [formGroup]="formMessage">
      <div class="form-group">
        <label for="texte">Posez votre question</label>
        <div class="position-relative">
          <input
            type="text"
            id="texte"
            formControlName="message"
            name="texte"
            class="form-control"
            [class.disabled-input]="!!loadingPost"
            autocomplete="off"
          />
          <app-loading class="ms-1 loading-post" [load]="loadingPost" display="inline"></app-loading>
        </div>
      </div>
      <button (click)="addMessage()" class="btn btn-primary mt-1" [disabled]="!formMessage.valid">Envoyer</button>
    </form>
  </div>
</div>
