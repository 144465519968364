<div class="d-flex flex-wrap gap-0_5 mt-1 justify-content-end">
    @for (affectation of affectations; track affectation.id) {
      <div class="d-flex align-items-center avatar-container position-relative">
        @if (affectation.utilisateur.avatar_url) {
          <img [src]="affectation.utilisateur.avatar_url" alt="avatar" class="avatar-affectation" />
        } @else {
          <img src="/assets/images/avatar_large.png" alt="avatar" class="avatar-affectation" />
        }
        <div class="user-details position-absolute bg-dark p-1 rounded">
          <div class="d-flex align-items-center gap-2">
            @if (affectation.utilisateur.avatar_url) {
              <img [src]="affectation.utilisateur.avatar_url" alt="avatar" class="avatar-affectation avatar-details" />
            } @else {
              <img src="/assets/images/avatar_large.png" alt="avatar" class="avatar-affectation avatar-details" />
            }
            <div>
              <div class="text-white">{{ affectation.utilisateur.first_name }} {{ affectation.utilisateur.last_name }}</div>
              <div class="text-white small">{{ affectation.role | titlecase }}</div>
            </div>
          </div>
        </div>
      </div>
    }
  </div>