import { Injectable, inject } from '@angular/core';
import { MetariscService } from '../metarisc/metarisc.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class OrganisationChoiceService {
  private readonly meta = inject(MetariscService);
  private readonly cookieService = inject(CookieService);

  getOrganisationCookie(): string | undefined {
    const organisation = this.getCookieValue('ORGANIZATION_CHOICE_ID');
    return organisation || undefined;
  }

  setOrganisationCookie(idOrganisation: string): void {
    if (idOrganisation) {
      this.setCookieValue('ORGANIZATION_CHOICE_ID', idOrganisation, 30);
    }
  }

  setOrganisationChoice(idOrganisation: string): void {
    this.setOrganisationCookie(idOrganisation);
    this.meta.getMetarisc().setActiveOrganisation(idOrganisation);
  }

  getCookieValue(name: string): string | undefined {
    return this.cookieService.check(name) ? this.cookieService.get(name) : undefined;
  }

  setCookieValue(name: string, value: string, expirationDate: number): void {
    this.cookieService.set(name, value, expirationDate, '/', undefined, true, 'Strict');
  }
}
