@if (layout && formGroup) {
  <div>
    <form [formGroup]="formGroup">
      @if (layout.tabs.length > 1) {
        <div class="tabs">
          @for (tab of layout.tabs; track tab.name) {
            <div
              class="tab"
              [class.greenTag]="tab.haveData"
              [class.active]="activeTab === tab.name"
              [class.ff-medium]="activeTab === tab.name"
              (click)="setActiveTab(tab.name)"
            >
              {{ tab.name }}
            </div>
          }
        </div>
      }

      <div class="tab-content frosted-glass">
        <div class="row">
          @for (item of currentTabContent; track item.name) {
            @switch (item.type) {
              @case ('title') {
                <h2>{{ item.name }}</h2>
              }
              @case ('separator') {
                <hr />
              }
              @case ('lineBreak') {
                <div class="line-break"></div>
              }
              @case ('field') {
                @switch (item.size) {
                  @case ('full') {
                    <div class="col-sm-12">
                      <app-universal-form-input
                        [field]="controlMap.get(parentPath ? parentPath + '.' + item.name : item.name)"
                        [filledMarker]="true"
                        [formGroupInstance]="formGroup"
                        [parentPath]="parentPath"
                        [showDescription]="item.showDescription"
                        [readOnly]="readOnly"
                      ></app-universal-form-input>
                    </div>
                  }
                  @case ('half') {
                    <div class="col-sm-6">
                      <app-universal-form-input
                        [field]="controlMap.get(parentPath ? parentPath + '.' + item.name : item.name)"
                        [filledMarker]="true"
                        [formGroupInstance]="formGroup"
                        [parentPath]="parentPath"
                        [showDescription]="item.showDescription"
                        [readOnly]="readOnly"
                      ></app-universal-form-input>
                    </div>
                  }
                }
              }
              @case ('dualSelect') {
                <div class="col-sm-12">
                  @if (!readOnly) {
                    <div class="d-flex">
                      <div class="filled-indicator">
                        @if (hasValue(controlMap.get(parentPath ? parentPath + '.' + item.name : item.name))) {
                          <i class="bi bi-check-circle"></i>
                        }
                      </div>
                      <div
                        class="form-control-readonly-label"
                        [class.is-filled]="
                          hasValue(controlMap.get(parentPath ? parentPath + '.' + item.name : item.name))
                        "
                      >
                        {{ getPrettyText(item.name) }}
                      </div>
                    </div>
                    <app-universal-form-dual-select
                      [options]="controlMap.get(parentPath ? parentPath + '.' + item.name : item.name)?.options ?? []"
                      [labels]="item.labels"
                      [formControlName]="item.name"
                      [displayLabel]="false"
                      [field]="controlMap.get(parentPath ? parentPath + '.' + item.name : item.name)"
                    ></app-universal-form-dual-select>
                  } @else {
                    <div [class.read-only]="readOnly">
                      <div class="d-flex">
                        <div class="form-control-readonly-label">
                          <u>{{ getPrettyText(item.name) }}</u>
                        </div>
                      </div>
                      <div class="form-control-readonly-value">
                        {{
                          getPrettyValue(
                            controlMap.get(parentPath ? parentPath + '.' + item.name : item.name)?.control?.value
                          )
                        }}
                      </div>
                    </div>
                  }
                </div>
              }
              @case ('datalist') {
                @switch (item.size) {
                  @case ('full') {
                    <div class="col-sm-12">
                      <app-universal-form-input
                        [field]="controlMap.get(parentPath ? parentPath + '.' + item.name : item.name)"
                        [filledMarker]="true"
                        [formGroupInstance]="formGroup"
                        [parentPath]="parentPath"
                        [showDescription]="item.showDescription"
                        [readOnly]="readOnly"
                        [datalist]="item.list"
                      ></app-universal-form-input>
                    </div>
                  }
                  @case ('half') {
                    <div class="col-sm-6">
                      <app-universal-form-input
                        [field]="controlMap.get(parentPath ? parentPath + '.' + item.name : item.name)"
                        [filledMarker]="true"
                        [formGroupInstance]="formGroup"
                        [parentPath]="parentPath"
                        [showDescription]="item.showDescription"
                        [readOnly]="readOnly"
                        [datalist]="item.list"
                      ></app-universal-form-input>
                    </div>
                  }
                }
              }
            }
          }
        </div>
      </div>
    </form>
  </div>
}
