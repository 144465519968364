@if (!url) {
  <h2 class="header">Export en cours</h2>
} @else {
  <h2 class="header">Export terminé</h2>
}
<div class="modal-body content text-center">
  @if (!url) {
    <div class="sub-title">(Ne pas fermer tant que l'export n'est pas terminé)</div>
  }
  @if (totalPages === 0) {
    <p>Veuillez patienter, préparation de l'export en cours...</p>
  } @else {
    @if (!data || data.length === 0) {
      <div class="data-flow"></div>
      <div class="progress" [attr.data-progress]="progress + '%'">
        <div
          class="progress-bar"
          role="progressbar"
          [style.width.%]="progress"
          aria-valuenow="progress"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <p>Récupération des données en cours...</p>
      <p>
        {{ currentIndex }} éléments sur
        {{ totalItems }} au total
      </p>
      @if (isLimited) {
        <div class="d-flex align-items-center gap-1 limit">
          <i class="bi bi-exclamation-triangle-fill text-warning fs-2"></i>
          <span class="limit-message">Votre requête est limitée aux {{ hardLimit }} premiers éléments.</span>
        </div>
      }
    }
    @if (url) {
      <p class="fs-3">{{ data.length }} éléments exportés.</p>
      <p class="fs-6">
        (Délimiteur : {{ delimiter.field }}  Encapsulation : {{ delimiter.wrap }}   Fin de ligne :
        {{ encodedEol }})
      </p>
      <p>Votre fichier CSV a été téléchargé automatiquement</p>
      <p>
        <a href="{{ url }}" download="{{ fileName }}">
          <div class="d-flex flex-column align-items-center download-button">
            <i class="bi bi-filetype-csv fs-1"></i>
            <p>{{ fileName }}</p>
          </div>
        </a>
      </p>
      <p class="text-muted"><i>(Cliquez pour le re-télécharger en cas de problème)</i></p>
    }
  }
</div>
<button type="button" class="btn btn-cancel-modal" (click)="onCancel()">
  <i class="bi bi-x-lg"></i>
</button>