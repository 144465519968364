@if (field) {
  <div class="d-flex">
    @if (filledMarker && !readOnly) {
      <div class="filled-indicator">
        @if (hasValue) {
          <i class="bi bi-check-circle"></i>
        }
      </div>
    }
    <div class="mb-1 flex-grow-1">
      <div class="col-sm-12" [class.read-only]="readOnly">
        @if (datalist) {
          <label
            class="form-label"
            [class.is-filled]="filledMarker && hasValue && !readOnly"
            [class.read-only-label]="readOnly"
            [for]="index !== undefined ? field.fullKey + '_' + index : field.fullKey"
          >
            {{ getPrettyText(field.label) }}
            @if (isRequired(field.control) && !readOnly) {
              <span class="required-field">*</span>
            }
          </label>
          @if (!readOnly) {
            <input
              [id]="index !== undefined ? field.fullKey + '_' + index : field.fullKey"
              class="form-control"
              [formControl]="$any(field.control)"
              type="text"
              [attr.list]="datalist[0] + '_list'"
            />
            <datalist [id]="datalist[0] + '_list'">
              <option *ngFor="let option of datalist" [value]="option">
                {{ option }}
              </option>
            </datalist>
          } @else {
            <div class="form-control-readonly">{{ $any(field.control).value || '-' }}</div>
          }
        } @else {
          @switch (field.type) {
            @case (ControlType.text) {
              <label
                class="form-label"
                [class.is-filled]="filledMarker && hasValue && !readOnly"
                [class.read-only-label]="readOnly"
                [for]="index !== undefined ? field.fullKey + '_' + index : field.fullKey"
              >
                {{ getPrettyText(field.label) }}
                @if (isRequired(field.control) && !readOnly) {
                  <span class="required-field">*</span>
                }
              </label>
              @if (field.maxLength && field.maxLength > 255) {
                @if (!readOnly) {
                  <textarea
                    [id]="index !== undefined ? field.fullKey + '_' + index : field.fullKey"
                    class="form-control"
                    [formControl]="$any(field.control)"
                  ></textarea>
                } @else {
                  <div class="form-control-readonly">{{ $any(field.control).value || '-' }}</div>
                }
              } @else {
                @if (!readOnly) {
                  <input
                    [id]="index !== undefined ? field.fullKey + '_' + index : field.fullKey"
                    class="form-control"
                    [formControl]="$any(field.control)"
                    type="text"
                  />
                } @else {
                  <div class="form-control-readonly">{{ $any(field.control).value || '-' }}</div>
                }
              }
              @if (!readOnly) {
                <small
                  [id]="
                    index !== undefined ? field.fullKey + '_' + index + '_description' : field.fullKey + '_description'
                  "
                  class="form-text text-muted"
                  [innerHTML]="showDescription ? processFieldDescription(field.description ?? '') : '<p></p>'"
                ></small>
              }
            }
            @case (ControlType.number) {
              <label
                class="form-label"
                [class.is-filled]="filledMarker && hasValue && !readOnly"
                [for]="field.fullKey"
                [class.read-only-label]="readOnly"
                >{{ getPrettyText(field.label) }}
                @if (isRequired(field.control) && !readOnly) {
                  <span class="required-field">*</span>
                }
              </label>
              @if (!readOnly) {
                <input
                  [id]="field.fullKey"
                  class="form-control"
                  [formControl]="$any(field.control)"
                  type="number"
                  [placeholder]="field.placeholder"
                />
                <small
                  [id]="field.fullKey + '_description'"
                  class="form-text text-muted"
                  [innerHTML]="showDescription ? processFieldDescription(field.description ?? '') : '<p></p>'"
                ></small>
              } @else {
                <div class="form-control-readonly">{{ $any(field.control).value || '-' }}</div>
              }
            }
            @case (ControlType.checkbox) {
              <div>
                <label
                  class="form-label"
                  [class.is-filled]="filledMarker && hasValue && !readOnly"
                  [for]="field.fullKey"
                  [class.read-only-label]="readOnly"
                  >{{ getPrettyText(field.label) }}
                  @if (isRequired(field.control) && !readOnly) {
                    <span class="required-field">*</span>
                  }
                </label>
                @if (!readOnly) {
                  <app-universal-form-check [formControl]="$any(field.control)"></app-universal-form-check>
                  <small
                    [id]="field.fullKey + '_description'"
                    class="form-text text-muted d-block"
                    [innerHTML]="showDescription ? processFieldDescription(field.description ?? '') : '<p></p>'"
                  ></small>
                } @else {
                  <div class="form-control-readonly">{{ getPrettyValue($any(field.control).value) || '-' }}</div>
                }
              </div>
            }
            @case (ControlType.date) {
              <label
                class="form-label"
                [class.is-filled]="filledMarker && hasValue && !readOnly"
                [for]="field.fullKey"
                [class.read-only-label]="readOnly"
                >{{ getPrettyText(field.label) }}
                @if (isRequired(field.control) && !readOnly) {
                  <span class="required-field">*</span>
                }
              </label>
              @if (!readOnly) {
                <app-date-time-selector
                  [id]="field.fullKey"
                  class="form-control"
                  [formControl]="$any(field.control)"
                  [dateOnly]="true"
                />
                <small
                  [id]="field.fullKey + '_description'"
                  class="form-text text-muted"
                  [innerHTML]="showDescription ? processFieldDescription(field.description ?? '') : '<p></p>'"
                ></small>
              } @else {
                <div class="form-control-readonly">{{ $any(field.control).value || '-' }}</div>
              }
            }
            @case (ControlType.datetime) {
              <label
                class="form-label"
                [class.is-filled]="filledMarker && hasValue && !readOnly"
                [for]="field.fullKey"
                [class.read-only-label]="readOnly"
                >{{ getPrettyText(field.label) }}
                @if (isRequired(field.control) && !readOnly) {
                  <span class="required-field">*</span>
                }
              </label>
              @if (!readOnly) {
                <app-date-time-selector
                  [id]="field.fullKey"
                  class="form-control"
                  [formControl]="$any(field.control)"
                />
                <small
                  [id]="field.fullKey + '_description'"
                  class="form-text text-muted"
                  [innerHTML]="showDescription ? processFieldDescription(field.description ?? '') : '<p></p>'"
                ></small>
              } @else {
                <div class="form-control-readonly">{{ $any(field.control).value || '-' }}</div>
              }
            }
            @case (ControlType.select) {
              <label
                class="form-label"
                [class.is-filled]="filledMarker && hasValue && !readOnly"
                [for]="field.fullKey"
                [class.read-only-label]="readOnly"
                >{{ getPrettyText(field.label) }}
                @if (isRequired(field.control) && !readOnly) {
                  <span class="required-field">*</span>
                }
              </label>
              @if (!readOnly) {
                <select [id]="field.fullKey" class="form-select" [formControl]="$any(field.control)">
                  <option [ngValue]="null" [disabled]="isRequired(field.control)" selected>
                    {{ field.placeholder }}
                  </option>
                  @for (option of field.options; track option) {
                    <option [ngValue]="option">{{ getPrettyValue(option) }}</option>
                  }
                </select>
                <small
                  [id]="field.fullKey + '_description'"
                  class="form-text text-muted d-block"
                  [innerHTML]="showDescription ? processFieldDescription(field.description ?? '') : '<p></p>'"
                ></small>
              } @else {
                <div class="form-control-readonly">{{ $any(field.control).value || '-' }}</div>
              }
            }
            @case (ControlType.array) {
              <label
                class="form-label-array"
                [class.is-filled]="filledMarker && hasValue && !readOnly"
                [for]="field.fullKey"
                [class.read-only-label]="readOnly"
                >{{ getPrettyText(field.label) }}
                @if (isRequired(field.control) && !readOnly) {
                  <span class="required-field">*</span>
                }
              </label>
              @if (!readOnly) {
                <small
                  [id]="field.fullKey + '_description'"
                  class="form-text text-muted"
                  [innerHTML]="showDescription ? processFieldDescription(field.description ?? '') : '<p></p>'"
                ></small>
              }
              <div class="d-flex flex-column gap-1">
                @if ($any(field.control).controls.length === 0 && arrayEmptyMessage) {
                  <app-no-result [title]="arrayEmptyMessage"></app-no-result>
                }
                @for (item of $any(field.control).controls; track item; let i = $index) {
                  <!-- Tableau d'objets -->
                  @if (isFormGroup(item)) {
                    <div [class.row-attribute]="!readOnly" [class.row-attribute-read-only]="readOnly" @itemAnimation>
                      <div class="attributes-container">
                        <!-- On essaie d'abord de balayer un éventuel layout -->
                        @for (layoutItem of getLayout(field.fullKey); track layoutItem; let j = $index) {
                          <div
                            class="attribute"
                            [class.full-width]="
                              getControlInfo(field.itemsControlInfo, layoutItem.name, item.controls[layoutItem.name])
                                ?.type === ControlType.object ||
                              getControlInfo(field.itemsControlInfo, layoutItem.name, item.controls[layoutItem.name])
                                ?.type === ControlType.array ||
                              layoutItem.size === 'full' ||
                              layoutItem.type === 'dualSelect'
                            "
                          >
                            @if (layoutItem.type === 'dualSelect') {
                              @if (!readOnly) {
                                <app-universal-form-dual-select
                                  [options]="field.itemsControlInfo[layoutItem.name].options ?? []"
                                  [labels]="layoutItem.labels"
                                  [formControl]="$any(item.controls[layoutItem.name])"
                                  [field]="
                                    getControlInfo(
                                      field.itemsControlInfo,
                                      layoutItem.name,
                                      item.controls[layoutItem.name]
                                    )
                                  "
                                  [displayLabel]="true"
                                  class="w-100"
                                ></app-universal-form-dual-select>
                              } @else {
                                <div class="read-only dual-select-read-only">
                                  <div class="form-control-readonly-container">
                                    <div class="form-control-readonly">
                                      {{ $any(item.controls[layoutItem.name])?.value }}
                                    </div>
                                  </div>
                                </div>
                              }
                            } @else if (layoutItem.type === 'datalist') {
                              <ng-container
                                *ngComponentOutlet="
                                  UniversalFormInputComponent;
                                  inputs: {
                                    field: getControlInfo(
                                      field.itemsControlInfo,
                                      layoutItem.name,
                                      item.controls[layoutItem.name]
                                    ),
                                    index: j,
                                    formGroupInstance: formGroupInstance,
                                    parentPath: parentPath,
                                    filledMarker: filledMarker,
                                    readOnly: readOnly,
                                    datalist: layoutItem.list || [],
                                  }
                                "
                              ></ng-container>
                            } @else {
                              <ng-container
                                *ngComponentOutlet="
                                  UniversalFormInputComponent;
                                  inputs: {
                                    field: getControlInfo(
                                      field.itemsControlInfo,
                                      layoutItem.name,
                                      item.controls[layoutItem.name]
                                    ),
                                    index: j,
                                    formGroupInstance: formGroupInstance,
                                    parentPath: parentPath,
                                    filledMarker: filledMarker,
                                    readOnly: readOnly,
                                  }
                                "
                              ></ng-container>
                            }
                          </div>
                        }
                        <!-- Fallback dans le cas ou le layout n'est pas défini -->
                        @if (getLayout(field.fullKey).length === 0) {
                          @for (attribute of item.controls | keyvalue; track attribute.key; let j = $index) {
                            <div
                              class="attribute"
                              [class.full-width]="
                                getControlInfo(field.itemsControlInfo, attribute.key, attribute.value)?.type ===
                                  ControlType.object ||
                                getControlInfo(field.itemsControlInfo, attribute.key, attribute.value)?.type ===
                                  ControlType.array
                              "
                            >
                              <ng-container
                                *ngComponentOutlet="
                                  UniversalFormInputComponent;
                                  inputs: {
                                    field: getControlInfo(field.itemsControlInfo, attribute.key, attribute.value),
                                    index: i,
                                    formGroupInstance: formGroupInstance,
                                    parentPath: parentPath,
                                    filledMarker: filledMarker,
                                    readOnly: readOnly,
                                  }
                                "
                              ></ng-container>
                            </div>
                          }
                        }
                      </div>
                      @if (!readOnly) {
                        <div class="action" (click)="removeItem($any(field.control), i)">
                          <i class="bi bi-trash"></i>
                        </div>
                      }
                    </div>
                  } @else {
                    <!-- Tableau de valeurs (string, number, boolean, etc.) -->
                    @if (getLayout(field.fullKey).length > 0 && getLayout(field.fullKey)[0].type === 'dualSelect') {
                      <div class="row" [class.row-attribute]="!readOnly" [class.row-attribute-read-only]="readOnly">
                        <div class="attributes-container">
                          @if (!readOnly) {
                            <app-universal-form-dual-select
                              [options]="field.options ?? []"
                              [labels]="getLayout(field.fullKey)[0].labels"
                              [formControl]="item"
                              [field]="field"
                              [displayLabel]="false"
                              class="w-100"
                            ></app-universal-form-dual-select>
                          } @else {
                            <div class="form-control-readonly">{{ $any(field.control).value[i] }}</div>
                          }
                        </div>
                        @if (!readOnly) {
                          <div class="action" (click)="removeItem($any(field.control), i)">
                            <i class="bi bi-trash"></i>
                          </div>
                        }
                      </div>
                    } @else {
                      <div class="row row-attribute">
                        <div class="attributes-container">
                          @if (field.options) {
                            @if (!readOnly) {
                              <select [formControl]="item" [id]="field.fullKey" class="form-select">
                                <option [ngValue]="null" [disabled]="isRequired(field.control)" selected>
                                  {{ field.placeholder }}
                                </option>

                                @for (option of field.options; track option) {
                                  <option [value]="option" [disabled]="field.control.value.includes(option)">
                                    {{ getPrettyValue(option) }}
                                  </option>
                                }
                              </select>
                            } @else {
                              <div class="form-control-readonly">{{ $any(field.control).value }}</div>
                            }
                          }
                        </div>
                        @if (!readOnly) {
                          <div class="action" (click)="removeItem($any(field.control), i)">
                            <i class="bi bi-trash"></i>
                          </div>
                        }
                      </div>
                    }
                  }
                }
              </div>
              @if (!readOnly) {
                <div class="d-flex justify-content-end mt-1">
                  <button
                    class="btn btn-primary"
                    type="button"
                    (click)="addItem()"
                    [disabled]="field.maxItems && $any(field.control).length >= field.maxItems"
                  >
                    + {{ getPrettyText('ajouter_' + field.key) }}
                  </button>
                </div>
              }
            }
            @case (ControlType.object) {
              <label
                class="form-label-array"
                [class.is-filled]="filledMarker && hasValue && !readOnly"
                [for]="field.fullKey"
                [class.read-only-label]="readOnly"
                >{{ getPrettyText(field.label) }}
                @if (isRequired(field.control) && !readOnly) {
                  <span class="required-field">*</span>
                }
              </label>
              @if (!readOnly) {
                <small
                  [id]="field.fullKey + '_description'"
                  class="form-text text-muted"
                  [innerHTML]="showDescription ? processFieldDescription(field.description ?? '') : '<p></p>'"
                ></small>
              }
              @if (hasValue) {
                <div class="row-attribute" @itemAnimation>
                  <div class="attributes-container">
                    <!-- On essaie d'abord de balayer un éventuel layout -->
                    @for (layoutItem of getLayout(field.fullKey); track layoutItem; let j = $index) {
                      <div
                        class="attribute"
                        [class.full-width]="
                          getControlInfo(
                            field.itemsControlInfo,
                            layoutItem.name,
                            $any(field.control).controls[layoutItem.name]
                          )?.type === ControlType.object ||
                          getControlInfo(
                            field.itemsControlInfo,
                            layoutItem.name,
                            $any(field.control).controls[layoutItem.name]
                          )?.type === ControlType.array
                        "
                      >
                        <ng-container
                          *ngComponentOutlet="
                            UniversalFormInputComponent;
                            inputs: {
                              field: getControlInfo(
                                field.itemsControlInfo,
                                layoutItem.name,
                                $any(field.control).controls[layoutItem.name]
                              ),
                              index: j,
                              formGroupInstance: formGroupInstance,
                              parentPath: parentPath,
                              filledMarker: filledMarker,
                              readOnly: readOnly,
                            }
                          "
                        ></ng-container>
                      </div>
                    }
                    <!-- Fallback dans le cas ou le layout n'est pas défini -->
                    @if (getLayout(field.fullKey).length === 0) {
                      @for (control of $any(field.control).controls | keyvalue; track control.key; let i = $index) {
                        <div class="attribute">
                          <ng-container
                            *ngComponentOutlet="
                              UniversalFormInputComponent;
                              inputs: {
                                field: getControlInfo(field.itemsControlInfo, $any(control.key), $any(control.value)),
                                index: 0,
                                filledMarker: filledMarker,
                                formGroupInstance: formGroupInstance,
                                parentPath: parentPath,
                                readOnly: readOnly,
                              }
                            "
                          ></ng-container>
                        </div>
                      }
                    }
                  </div>
                  <div class="action" (click)="removeObject()">
                    <i class="bi bi-trash"></i>
                  </div>
                </div>
              } @else {
                <div class="d-flex justify-content-end mt-1">
                  <button class="btn btn-primary" type="button" (click)="addObject()">
                    + {{ getPrettyText('ajouter_' + field.key) }}
                  </button>
                </div>
              }
            }
            @case (ControlType.placeholder) {
              <label
                class="form-label-placeholder"
                [class.is-filled]="filledMarker && hasValue && !readOnly"
                [for]="field.fullKey"
                [class.read-only-label]="readOnly"
                >{{ getPrettyText(field.label) }}
                @if (isRequired(field.control) && !readOnly) {
                  <span class="required-field">*</span>
                }
              </label>
              @if (!readOnly) {
                <small
                  [id]="field.fullKey + '_description'"
                  class="form-text text-muted"
                  [innerHTML]="showDescription ? processFieldDescription(field.description ?? '') : '<p></p>'"
                ></small>
              }
              @for (child of field.childs; track child.key) {
                <app-universal-form-input
                  [field]="child"
                  [parentPath]="parentPath"
                  [readOnly]="readOnly"
                ></app-universal-form-input>
              }
            }
            @case (ControlType.keyvalue) {
              <label
                class="form-label-placeholder"
                [class.is-filled]="filledMarker && hasValue && !readOnly"
                [for]="field.fullKey"
                [class.read-only-label]="readOnly"
                >{{ getPrettyText(field.label) }}
                @if (isRequired(field.control) && !readOnly) {
                  <span class="required-field">*</span>
                }
              </label>
              @if (!readOnly) {
                <small
                  [id]="field.fullKey + '_description'"
                  class="form-text text-muted"
                  [innerHTML]="showDescription ? processFieldDescription(field.description ?? '') : '<p></p>'"
                ></small>
              }
              @for (item of $any(field.control).controls | keyvalue; track item; let i = $index) {
                @if (i === 0) {
                  <div class="row mt-3">
                    <div class="col-md-5">Clé</div>
                    <div class="col-md-5">Valeur</div>
                  </div>
                  <hr />
                }
                <div class="row">
                  <div class="col-md-5">
                    {{ item.key }}
                  </div>
                  <div class="col-md-5">
                    {{ $any(item.value).value }}
                  </div>
                </div>
              }

              @if (currentKeyValueFormGroup) {
                <hr />
                <div class="row">
                  <div class="col-md-5">
                    <label class="form-label" [class.is-filled]="filledMarker && hasValue && !readOnly" for="key">
                      Clé
                      @if (!readOnly) {
                        <span class="required-field">*</span>
                      }
                    </label>
                    @if (!readOnly) {
                      <input
                        type="text"
                        class="form-control"
                        id="key"
                        [formControl]="$any(currentKeyValueFormGroup.get('key'))"
                      />
                    } @else {
                      <div class="form-control-readonly">{{ $any(currentKeyValueFormGroup.get('key')).value }}</div>
                    }
                  </div>
                  <div class="col-md-5">
                    <label
                      class="form-label"
                      [class.is-filled]="filledMarker && hasValue && !readOnly"
                      [class.read-only-label]="readOnly"
                      for="value"
                    >
                      Valeur
                      @if (!readOnly) {
                        <span class="required-field">*</span>
                      }
                    </label>
                    @if (!readOnly) {
                      <input
                        type="text"
                        class="form-control"
                        id="value"
                        [formControl]="$any(currentKeyValueFormGroup.get('value'))"
                      />
                    } @else {
                      <div class="form-control-readonly">{{ $any(currentKeyValueFormGroup.get('value')).value }}</div>
                    }
                  </div>
                  @if (!readOnly) {
                    <div class="col-md-1 d-flex justify-content-end align-items-start mini-btn">
                      <button type="button" class="btn btn-sm btn-outline-danger square-btn" (click)="removeKeyValue()">
                        <i class="bi bi-trash"></i>
                      </button>
                    </div>
                    <div class="col-md-1 d-flex justify-content-end align-items-start mini-btn">
                      <button type="button" class="btn btn-sm btn-success square-btn" (click)="saveKeyValue()">
                        <i class="bi bi-check"></i>
                      </button>
                    </div>
                  }
                </div>
              }
              @if (!currentKeyValueFormGroup && !readOnly) {
                <div class="d-flex justify-content-end mt-1">
                  <button class="btn btn-primary" type="button" (click)="addKeyValue()">+ Ajouter une valeur</button>
                </div>
              }
            }
            @default {
              ERROR NOT SUPPORTED: {{ field.type }}
            }
          }
        }

        @if (field.control && field.control.invalid && (field.control.dirty || field.control.touched)) {
          <div class="error-message">
            {{ getError(field.control) }}
          </div>
        }
      </div>
    </div>
  </div>
}
