import { Component, OnInit } from '@angular/core';
import { Collection } from '@metarisc/metarisc-js';
import { PaginationData } from '@metarisc/metarisc-js/lib/collection';
import { Dossier } from '@metarisc/metarisc-js/src/model/Dossier';
import { MetariscService } from 'src/app/services/metarisc/metarisc.service';
import { ListDossierItemComponent } from 'src/app/shared/components/lists-item/list-dossier-item/list-dossier-item.component';
import { LoadingComponent } from 'src/app/shared/components/loading/loading.component';
import { NoResultComponent } from 'src/app/shared/components/no-result/no-result.component';
import { RecapDossierComponent } from 'src/app/shared/components/recap-dossier/recap-dossier.component';
import { UtilitairesService } from 'src/app/services/utilitaires/utilitaires.service';
import { PaginateComponent } from 'src/app/shared/components/paginate/paginate.component';
import { GeneralSearchComponent } from '../../shared/components/general-search/general-search.component';
import { Univers } from 'src/app/features/recherche/services/recherche-service/recherche.service';

@Component({
  templateUrl: './suivi-des-dossiers-page.component.html',
  styleUrls: ['./suivi-des-dossiers-page.component.scss'],
  standalone: true,
  imports: [
    ListDossierItemComponent,
    NoResultComponent,
    RecapDossierComponent,
    LoadingComponent,
    PaginateComponent,
    GeneralSearchComponent,
  ],
})
export class SuiviDesDossiersPage implements OnInit {
  nbDossierByPage = 10;

  dossiers: Dossier[] = [];
  pagination?: PaginationData;
  loading?: Promise<unknown>;

  metariscClient = this.metariscService.getMetarisc();
  dossierCollection?: Collection<Dossier>;

  univers_list: Univers[] = [
    {
      libelle: 'Dossier',
      endpoint: '/dossiers',
      search_method: 'objet',
      filters: ['type_dossier', 'workflow_actif', 'affecte', 'erp_lie', 'pei_lie'],
    },
  ];

  constructor(
    private metariscService: MetariscService,
    private utilService: UtilitairesService,
  ) {}

  ngOnInit(): void {
    this.dossierCollection = this.metariscClient.dossiers?.paginateDossiers();
  }

  collection?: Collection<Dossier>;

  loadPage(page: number): void {
    if (this.collection) {
      this.utilService.scrollTop();

      this.loading = this.collection?.fetchPage(page, this.nbDossierByPage).then(async (response) => {
        this.dossiers = [];
        // Mise à jour des données de notification
        if (response.data.data && response.data.data.length > 0) {
          for (const dossier of response.data.data) {
            this.dossiers.push(dossier);
          }
        }
        // Mise à jour des données de pagination
        this.pagination = response.data.meta?.pagination as PaginationData;
      });
    }
  }

  search(collection: Collection<Dossier>): void {
    this.collection = collection;
    this.loadPage(1);
  }
}
